// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.container {
  position: absolute;
  top: -28px;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 28px;
}
