// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: absolute;
  z-index: $zIndexLayer1;
  transform: scale(0.8) translate(-50px, -60px);
  border: 1px solid $grey5;
  box-shadow: 0 0 4px 2px $grey5;
}
