// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.staticPageWrapper .staticPage {
  @extend %text;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin: 10px 0;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    margin: 48px 0 26px;
    padding: 0;
    color: $grey60;
    text-transform: uppercase;

    &:first-child { margin-top: 8px; }
  }

  h2 {
    font-size: 18px;
  }

  ul,
  ol {
    list-style-type: circle;
    padding: 10px 20px;
  }
}
