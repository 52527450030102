// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.modal {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1000px;
  padding-bottom: 30px;

  & > button {
    align-self: flex-end;
  }

  & > div:last-of-type {
    justify-self: flex-end;
  }
}

.content {
  display: flex;
  min-height: 400px;
}

.leftSection {
  border-right: 1px solid $grey10;
  padding-right: 20px;
  width: 35%;
}

.rightSection {
  width: 65%;
  padding-left: 20px;
}
