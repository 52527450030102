// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: relative;
  vertical-align: middle;
  padding-left: 5px;
  text-align: left;
  font-size: 11px;
  font-weight: 100;
  text-transform: uppercase;
  color: $slate;

  a {
    cursor: pointer;
    text-decoration: none;
    color: $slate;
  }

  i {
    position: absolute;
    top: calc(50% - 8px);
    color: $black;
    font-size: 14px;
    margin-left: 3px;
  }
}

.sortable {
  cursor: pointer;
  user-select: none;
}
