// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  display: flex;
  text-align: center;
  margin: 10px 0 0;
}

.button {
  flex-grow: 1;
  padding: 5px 10px;
  border-right: 1px solid $grey20;
  cursor: pointer;

  &:hover,
  &:active {
    color: $orange;
  }

  &:last-child {
    border-right: none;
  }
}
