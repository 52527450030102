.appId {
  margin-bottom: 200px;
}

.contentBox {
  margin-bottom: 20px;
  line-height: 1.4;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    font-weight: 900;
  }
}
