// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  display: flex;
  align-items: center;
}

.switch {
  display: inline-block;
  position: relative;
  background: $grey10;
  transition: .3s;
  border-radius: 8px;
  width: 28px;
  height: 16px;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 3px;
    bottom: 3px;
    background-color: $grey35;
    transition: .3s;
    border-radius: 50%;
  }
}

.input {
  display: none;
}

.checked .switch {
  background-color: $cyan;

  &::before {
    background-color: $white;
    transform: translateX(12px);
  }
}

.children {
  cursor: pointer;
  font-size: 14px;
  padding-left: 5px;
}

.disabled {
  .switch {
    background: $grey5;
  }

  .switch::before {
    background-color: $grey20;
  }

  .label {
    color: $grey20;
  }
}
