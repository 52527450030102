// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

$appsListWidth: 220px;
$appsListMaxHeight: 455px;
$maxAppNameWidth: $appsListWidth - 95px;
$planListHeight: 325px;
$planListContainerHeight: $planListHeight + 115px;
