// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$wrapper-height: 160px;
$checkerboard-scale: 10px;
$checkerboard-gradient: linear-gradient(
  45deg,
  $grey5 25%,
  transparent 25%,
  transparent 75%,
  $grey5 75%
);

.imageWrapper {
  background-image: $checkerboard-gradient, $checkerboard-gradient;
  background-position: 0 0, $checkerboard-scale $checkerboard-scale;
  background-size: (2 * $checkerboard-scale) (2 * $checkerboard-scale);
  display: flex;
  justify-content: center;
  align-items: center;
  height: $wrapper-height;
  flex-direction: column;
}

.image {
  max-height: $wrapper-height;
  max-width: 100%;
}

.assetsSubsection {
  > div {
    border: none;
  }
}

.assetDownload {
  border: 3px solid $grey5;
  border-top: none;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 14px;

  .downloadButton {
    border: 3px solid $white;
    position: relative;
    bottom: -1px;
    height: 12px;
  }
}

.screenshotNavigation {
  display: inline-block;
  margin-left: 10px;

  .item {
    display: inline-block;
    padding: 0 3px;
    cursor: pointer;

    &.active {
      color: $orange;
    }
  }
}
