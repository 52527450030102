// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.ValueDropdownOption {
  padding: 12px 30px;

  .DisplayValue {
    color: $baseTextColor;
    font-weight: bold;
  }

  &[data-focused="true"] {
    background-color: $grey40;

    .DisplayValue {
      color: $white;
    }
  }
}

.Icon {
  object-fit: contain;
  display: inline-block;
  margin-right: 10px;
}
