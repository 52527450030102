// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.loader {
  flex-direction: row;
}

.message {
  padding-left: 10px;
  font-weight: 400;
  color: $grey45;
  font-size: 14px;
  font-style: italic;
}

.center {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.left {
  align-items: center;
}

.small {
  font-size: 14px;
}

.medium {
  font-size: 16px;
}

.large {
  font-size: 18px;
}

.block {
  display: flex;
}

.inline {
  display: inline-flex;
}
