// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$checkerboardScale: 10px;
$checkerboardGradient: linear-gradient(
  45deg,
  $grey5 25%,
  transparent 25%,
  transparent 75%,
  $grey5 75%
);

%imageWrapper {
  background-image: $checkerboardGradient, $checkerboardGradient;
  background-position: 0 0, $checkerboardScale $checkerboardScale;
  background-size: (2 * $checkerboardScale) (2 * $checkerboardScale);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;

  > img {
    max-height: 100%;
  }
}

.pageWrapper {
  padding-top: 30px;

  .assetsSection {
    > div {
      border: none;
      padding: 0;
    }
  }

  .logo {
    @extend %imageWrapper;
    width: 160px;
  }

  .background {
    @extend %imageWrapper;
    width: 285px;
  }

  .liveThumbnail {
    @extend %imageWrapper;
    width: 91px;
    height: 50px;
  }

  .description {
    color: $grey50;
    font-size: 12px;
  }
}

.detailsTable {
  th {
    padding: 15px 10px 0 0;
    text-align: left;
    font-size: 11px;
    font-weight: 100;
    text-transform: uppercase;
    color: $slate;

    &:last-child {
      padding-right: 0;
    }
  }

  tbody {
    tr {
      height: 45px;
      border-bottom: 1px solid $grey10;

      td {
        padding-right: 10px;
        font-size: 14px;
        vertical-align: middle;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
