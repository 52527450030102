// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.header {
  display: inline-flex;
  align-items: center;
}

.icon {
  color: $cyan;
  padding: 0 5px;
}

.iconWithTooltip {
  cursor: pointer;
  font-weight: normal;
  text-transform: initial;
}
