// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  display: inline-block;
}

.popup {
  min-width: 150px;
  max-width: 300px;
  color: $grey90;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  text-transform: none;
  line-height: 16px;
  white-space: pre-line;
  word-break: break-word;

  ul {
    list-style-type: disc;
    padding-left: 2em;
  }

  strong {
    font-weight: bold;
  }
}

.title {
  font-weight: bold;
}
