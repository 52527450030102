// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "formElements";

.radio {
  padding: 5px 0;
  display: flex;
  align-items: center;

  input[type="radio"] {
    @extend %element;

    width: auto;
    display: inline;
    margin: 2px 5px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    font-weight: 700;
    color: $baseTextColor;
    transition: color .3s;
    vertical-align: middle;
  }
}

.disabled label {
  color: $grey15;
}

.icon {
  margin-left: auto;
}
