// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.snapModal {
  .iconSelect {
    @extend %elementWrapper;
    display: flex;

    .iconLabel {
      flex: 1;
    }
  }

  .options {
    @extend %elementWrapper;
  }

  .textWrapper {
    margin-bottom: 20px;
    border-bottom: 1px solid $grey20;
  }
}
