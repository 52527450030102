// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.titleIcon {
  vertical-align: bottom;
}

.httpWarnText {
  margin-bottom: 10px;
}

.description {
  margin-bottom: 20px;
}

.fileUpload {
  margin-bottom: 10px;
}

.fileTypeHintContainer {
  color: $slate;
  margin-top: 15px;
}

.linkFromWebContainer {
  margin-top: 5px;
  text-align: center;
  color: $slate;
  font-weight: lighter;
}

.linkFromWebInput {
  display: inline-block;
  min-width: 50%;
}

.errorMessage {
  margin: 20px 0 0;
}
