// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "formElements";

$padding: 10px;

.wrapped,
.simple {
  line-height: 1;

  select {
    @extend %element;

    appearance: none;
    border-radius: 0;
    padding: $padding;
    cursor: pointer;

    &:-moz-focusring { // sass-lint:disable-line no-vendor-prefixes
      color: transparent;
      text-shadow: 0 0 0 $black;
    }
  }

  select:disabled {
    @extend %disabled;
  }
}

.wrapped {
  @extend %elementWrapper;
}

.selectWrapper {
  position: relative;

  &::after {
    content: "\e001";
    font-family: "grabson-micro", sans-serif;
    font-size: 14px;
    line-height: 28px;
    position: absolute;
    top: $padding;
    right: $padding;
    pointer-events: none;
    color: $slate;
  }
}

.children,
.error {
  margin: 8px 0;
}

.compact {
  border-style: none;
  display: inline-block;
  width: 200px;
  margin: 0px;

  select {
    font-size: 14px;
    padding: 6px;
    margin: 0px;
  }

  .selectWrapper::after {
    font-size: 10px;
    line-height: 14px;
    right: $padding;
  }
}

.horizontal {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
}

.leftPositionedCompact {
  width: unset;
  padding-bottom: 0;

  select {
    width: 200px;
  }
}

.leftPositionedBig {
  .selectWrapper {
    flex-grow: 1;
  }

  select {
    margin: 0;
  }
}
