// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.content {
  display: flex;
  align-items: center;

  & > * { // sass-lint:disable-line no-universal-selectors
    margin-right: 10px;
  }

  i:hover {
    cursor: pointer;
  }
}

.active {
  color: $orange;
}

.disable {
  color: $slate;
}
