// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.FilterDropdownOption {
  padding: 12px 30px;

  .Command {
    color: $baseTextColor;
    font-weight: bold;
  }

  .Example {
    color: $olive;
    font-weight: bold;
  }

  .Description {
    clear: both;
    display: block;
    padding-top: 9px;
    color: $grey45;
    font-size: 11px;
    max-width: 100%;
    box-sizing: content-box;
    text-overflow: ellipsis;
  }

  .SampleValues {
    clear: both;
    display: block;
    padding-top: 9px;
    color: $grey30;
    font-size: 10px;
    font-style: italic;
    max-width: 100%;
    box-sizing: content-box;
  }

  &[data-focused="true"] {
    background-color: $grey40;

    .Command {
      color: $white;
    }

    .Example {
      color: $sunshine;
    }

    .Description {
      color: $grey5;
    }

    .SampleValues {
      color: $grey30;
    }
  }
}
