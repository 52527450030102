// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.topBanner {
  position: absolute;
  margin: 10px 0px 20px 0px;
  top: 0px;
  left: 0px;
  width: calc(100% - 10px);
  padding: 15px 10px 15px 0px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: $grey20;
  color: $white;
}

.topBannerText {
  margin-left: 40px;
}

.icon {
  padding-right: 5px;
}

.tooltipIcon {
  font-weight: 100;
  margin-left: 5px;
  font-size: 10px;
  color: $white;
  vertical-align: top;
}