// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.row {
  margin-top: 30px;
}
.link {
  color: $blue;
  text-decoration: underline;
}
.adTypeRow {
  margin-top: -100px;
}
