// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "components/typography/Link/link";

.formContent {
  padding: 45px;
  background: $grey2;

  a {
    @include createLinkColors($blue);
  }

  p {
    line-height: 1.5;
    font-size: 14px;
    color: $grey65;
  }

  strong {
    font-weight: 600;
  }

  code {
    font-family: monospace;
  }

  ul {
    list-style-type: disc;
    padding-left: 2em;
  }

  li {
    margin: 5px 0;
  }

  .section {
    margin: 20px auto;
    width: 80%;
    max-width: 1000px;
    position: relative;

    h3 {
      margin-bottom: 30px;
    }
  }

  .warning {
    margin-bottom: 30px;

    .warningIcon {
      margin: -12px 8px 40px 0;
      color: $orange;
      float: left;
    }
  }
}

.formRow {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .label {
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: $grey70;
  }

  .labelCol {
    margin-right: 50px;
    max-width: 500px;
  }

  .inputCol {
    flex: 0.6;
    max-width: 300px;
    position: relative;
  }

  .clearValueButton {
    position: absolute;
    top: 6px;
    right: -60px;
    color: $grey15;
    transition: color 0.3s;

    &:active,
    &:focus,
    &:hover {
      color: $orange;
    }
  }
}

.formArrayRow {
  margin-top: 40px;

  .arrayCol {
    position: relative;
    margin: 0 0 0 50px;
    max-width: 400px;
  }

  .arrayCol:last-child::before {
    content: ":";
    position: absolute;
    left: -26px;
    top: 22px;
  }

  .descriptionCol {
    margin-left: 50px;
    max-width: 850px;

    abbr {
      color: $orange;
    }

    button {
      padding-left: 10px;
    }

    label {
      display: block;
      margin-bottom: 30px;
    }

    textarea {
      height: 90px;
    }

    .descriptionField {
      transition: max-height 0.5s;
      max-height: 500px;
      display: block;
      overflow: hidden;

      &.hidden {
        max-height: 0;
      }
    }
  }

  .label {
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: $grey70;
  }

  .description {
    margin-bottom: 50px;
  }

  .actionButton {
    position: absolute;
    left: -70px;
    top: 6px;
  }

  .addFieldButton,
  .removeFieldButton {
    color: $green;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.2);
    }
  }

  .removeFieldButton {
    color: $orange;
  }
}

.buttonsRow {
  &.top {
    margin: 4px 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  &.bottom {
    margin-top: 35px;
  }

  .hidden {
    opacity: 0;
  }

  button {
    transition: opacity 0.3s, background 0.3s, color 0.3s;
  }
}
