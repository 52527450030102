// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.popup {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;

  .arrow {
    top: -5px;
  }

  .popupContent {
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    line-height: 1.33;
    font-style: italic;
    color: $grey90;
    position: absolute;
    display: none;
  }

  &:hover {
    .popupContent {
      display: block;
      white-space: normal;
      word-break: break-word;
      width: 55%;
      z-index: 999;
    }
  }
}
