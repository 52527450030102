@import "components/typography/Link/link";

.formContent {
  padding: 45px;
  background: $grey2;

  a {
    @include createLinkColors($blue);
  }

  p {
    line-height: 1.5;
    font-size: 14px;
    color: $grey65;
  }

  strong {
    font-weight: 600;
  }

  code {
    font-family: monospace;
  }

  ul {
    list-style-type: disc;
    padding-left: 2em;
  }

  li {
    margin: 5px 0;
  }

  .section {
    margin: 20px auto;
    width: 80%;
    max-width: 1000px;
    position: relative;

    h3 {
      margin-bottom: 30px;
    }
  }

  .warning {
    margin-bottom: 30px;

    .warningIcon {
      margin: -12px 8px 40px 0;
      color: $orange;
      float: left;
    }
  }
}
