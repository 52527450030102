// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.environment {
  display: flex;
  align-items: center;
}

.nameColumn {
  padding: 10px 0;
}

.approved {
  color: $green;
}

.rejected {
  color: $orange;
}

.pending {
  color: $sunshine;
}
