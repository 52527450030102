// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "formElements";

.radioGroup {
  @extend %elementWrapper;
}

.radioGroupInline .item {
  display: inline-block;
  margin-right: 10px;
}

.radioGroupTabs {
  border-bottom: 0;
  margin: 0;
  font-size: 11px;

 .item {
   margin-right: 20px;
 }

  label {
    color: lighten($blue, 10%);
    font-weight: 500;
    margin-left: 3px;
  }
}
