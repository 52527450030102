// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "shared";

.uploaderIdleContainer {
  @extend %uploaderContainer;
  background: $grey5;
  padding: 36px 0;
  text-align: center;
}
