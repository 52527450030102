// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// sass-lint:disable no-vendor-prefixes

$gradient-start-color: #ff3232;
$gradient-medium-color: #ffff00;
$gradient-end-color: #00bf00;
$border-color: #00ADEE;

.scoreComponent {
  margin: 0;
  padding: 24px 0;
  position: relative;
}

.scoreValue {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: auto;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(to right, $gradient-start-color 0%, $gradient-medium-color 50%, $gradient-end-color 100%);/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$gradient-start-color, endColorstr=$gradient-end-color,GradientType=1 );/* IE6-9 */
}

input[type="range"]::-webkit-slider-thumb {
  cursor: ew-resize;
  margin-top: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $border-color;
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;/* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
