// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  display: flex;
  align-items: center;
  user-select: none;
}

.avatars {
  display: flex;
  margin: 0 5px 0 10px;
}

.avatar {
  border: 2px solid $grey15;
  margin-left: -10px;
}

.arrowIcon {
  margin-left: 5px;
}

.name {
  vertical-align: middle;
}
