// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.name {
  font-weight: bold;
}

.column {
  color: $slate;
}

.menu {
  float: right;
}
