// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.link {
  color: $blue;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid $slate;
  transition: color 0.6s;

  &:hover {
    color: $orange;
  }

  &:last-child {
    border-right: none;
  }
}

.active {
  color: $orange;
}
