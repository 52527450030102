// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  border-color: $grey10;
}

.content {
  display: flex;
  align-items: center;
}

.buttons {
  margin-left: 150px;

  > button {
    margin-left: 10px;
  }
}

.green {
  color: $green;
  text-transform: uppercase;
}

.red {
  color: $orange;
  text-transform: uppercase;
}
