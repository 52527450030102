// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.name {
  font-weight: bold;
  color: $blue;
}

.date {
  color: $slate;
}

.menu {
  float: right;
}

%schedule {
  text-transform: capitalize;
  font-weight: bold;

}

.scheduled {
  @extend %schedule;
  color: $sunshine;
}

.ongoing {
  @extend %schedule;
  color: $green;
}

.finished {
  @extend %schedule;
  color: $slate;
}

.disabled {
  @extend %schedule;
  color: $orange;
}
