// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  color: $blue;
}

.nameColumn {
  padding-left: 10px;
}

.actionMenuColumn {
  float: right;
}

.columnValueText:not(.disabled) {
  cursor: pointer;
  &:hover {
    color: lighten($blue, 10%);
  }
}

.columnValueButton {
  text-transform: uppercase;
}

.icon {
  color: $black;
}
