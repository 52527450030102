// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/typography/Link/link";
@import "components/popups/Tooltip/tooltip";

.onHold {
  @include createLinkColors($sunshine, lighten($sunshine, 10%));
}

.rejected {
  @include createLinkColors($orange, lighten($orange, 10%));
}

.moreDetails {
  margin-top: 20px;
}

.content {
  text-align: center;

  i {
    padding-left: 5px;
  }
}
