// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.boxWrapper {
  margin-bottom: 40px;
}

.centeredRow {
  align-items: center;
}

.buttonWrapper {
  text-align: right;
  flex: 0.33;
}

.viewSubmittedButton {
  border-radius: 13px;
}

.informationWrapper {
  border: 4px solid $olive;
  border-radius: 2px;
  padding: 25px;
}
