// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  .label {
    display: inline-block;
    margin: 4px 0;

    .tooltip {
      .labelContent {
        color: $slate;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;

        .tooltipIcon {
          margin-left: 5px;
        }

        .info {
          font-size: small;
          font-style: italic;
        }

        .asterisks {
          color: $orange;
        }

        &.disabled {
          color: $grey15;

          .asterisks {
            color: $grey15;
          }
        }
      }

      strong {
        font-weight: bold;
      }
    }
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }
}
