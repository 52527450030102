// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  padding: 0 40px;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.header {
  text-transform: uppercase;
  padding: 10px 0;
  color: $grey60;
}

.info {
  @extend %text;
  padding: 15px 0;
  color: $grey60;
  font-style: italic;
}

.button {
  margin: 10px 0;
}
