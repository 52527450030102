// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.status {
  font-size: 12px;
  border: 1px solid $grey30;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 6px;
  margin: 0 5px;
  vertical-align: middle;
}
