// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: relative;
  overflow: hidden;
  min-height: 600px;
}

.actionMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
