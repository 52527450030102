// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.pdfViewer {
  display: inline-block;
  width: 45%;
  margin: 0 2%;
  box-sizing: border-box;
}

.agreementContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.checkbox {
  width: 70%;
  border-bottom: 1px solid $grey10;
  padding-top: 10px;
  padding-bottom: 20px;
}

.header {
  margin: 0 0 20px;
}

.revenueWarning {
  margin: 25px 0 0;
}
