// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$element-border-color: $grey20;

%elementWrapper {
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  border-color: $grey10;
}

%element {
  display: block;
  border-radius: 0;
  padding: 10px;
  font-size: 18px;
  margin: 8px 0;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  outline: 0 solid transparent;
  font-family: "Montserrat", sans-serif;
  border: 2px solid;
  box-sizing: border-box;

  &.error {
    border-color: $orange;
  }

  &.normal {
    border-color: $element-border-color;
    background-color: $grey5;

    &:active,
    &:focus {
      border-color: $cyan;
    }
  }

  &:active,
  &:focus {
    background-color: $white;
  }
}

%readonly {
  background-color: $grey25;
  color: $grey60;
  cursor: default;

  &:active,
  &:focus {
    background-color: $grey25;
    border-color: $grey20;
  }
}

%disabled {
  background-color: $grey25;
  color: $grey50;
  cursor: default;
  opacity: 1;
  -webkit-text-fill-color: $grey50; // sass-lint:disable-line no-vendor-prefixes
  -webkit-opacity: 1; // sass-lint:disable-line no-vendor-prefixes, no-misspelled-properties

  &:active,
  &:focus {
    background-color: $grey25;
    border-color: $grey20;
  }
}
