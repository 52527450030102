// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid $orange;
  padding: 25px;
  margin: 25px 0;
  text-align: center;
}

.error {
  border-color: 4px solid $orange;
}

.info {
  border-color: $sunshine;
}

.success {
  border-color: $green;
}
