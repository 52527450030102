// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$messageMarginBottom: 20px;
$messagePaddingLeft: 43px;
$markdownCodeBg: rgb(243, 243, 243);

@mixin textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TableRowCollapsed {
  cursor: pointer;

  & * { // sass-lint:disable-line no-universal-selectors
    cursor: pointer !important;  // sass-lint:disable-line no-important
  }
}

.MessageContent {
  padding-top: 10px;
  padding-left: $messagePaddingLeft;
  margin-bottom: $messageMarginBottom;
}

.MessageContentExpanded {
  margin-bottom: 25px;
  display: flex;

  .MessageMarkdown {
    width: 0;
    flex-grow: 1;
    p {
      overflow-wrap: break-word;
    }
  }
}

.MessageContentCollapsed .CollapsedText {
  display: flex;

  .MessageMarkdown {
    width: 0;
    flex-grow: 1;

    p {
      @include textOverflow;
    }
  }
}

.MessageMarkdown {
  code {
    background-color: $markdownCodeBg;
  }
}

.CloseButtonColumn {
  position: relative;
}

.CloseButton {
  color: lighten($blue, 10%);
  position: absolute;
  bottom: $messageMarginBottom;
  right: 10px;
  cursor: pointer;

  &:hover {
    color: lighten($blue, 20%);
  }
}

.CloseButtonIcon {
  color: $grey30;
  margin-left: 5px;
}

.closeButtonText {
  vertical-align: middle;
}

@mixin otherItemsList {
  margin: 15px 0 0;
  padding: 15px 0 0;
  border-top: 1px solid $grey10;

  .ItemsListHeader {
    margin-bottom: 5px;
  }

  .ListItem {
    margin-bottom: 3px;
  }
}

.TasksList {
  @include otherItemsList;
}

.DevicesList {
  @include otherItemsList;
}
