// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "formElements";

.wrapped,
.simple {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    @extend %element;
  }

  input:read-only,
  input[type="text"]:read-only,
  input[type="number"]:read-only,
  input[type="password"]:read-only,
  input[type="email"]:read-only {
    @extend %readonly;
  }

  input:disabled,
  input[type="text"]:disabled,
  input[type="number"]:disabled,
  input[type="password"]:disabled,
  input[type="email"]:disabled {
    @extend %disabled;
  }
}

.wrapped {
  @extend %elementWrapper;
}

.children,
.error {
  margin: 8px 0;
}
