// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: $blue;
  margin-right: 10px;
  a {
    display: inline-block;
    text-decoration: none;
    padding: 10px;
    color: $blue;
  }

  a:hover {
    color: $orange;
  }
}


.arrowIcon {
  margin: 0 5px;
}

.buttonText {
  vertical-align: middle;
}

.previous {
  display: inline-flex;
  flex-shrink: 0;
}

.next {
  display: inline-flex;
  flex-shrink: 0;
}