// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.item {
  font-size: 12px;
  color: $blue;
  padding: 6px 0;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}

.overlay {
  color: $grey50;
}
.disabled {
  cursor: auto;
  color: $grey30;

  &:hover {
    color: $grey30;
  }
}
