// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.notification {
  padding: 20px 20px 30px 20px;
  border-bottom: 2px solid $grey15;
  background: $white;
  display: flex;
  flex-direction: column;
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      flex-grow: 2;
      padding-left: 20px;
      color: $blue;
    }
    .date {
      align-self: flex-start;
      color: $grey55;
      font-size: 12px;
    }
  }
  .message {
    @extend %text;
    font-size: 14px;
    padding: 10px 0 0 50px;
    span {
      overflow-wrap: anywhere;
      font-style: italic;
    }
  }
  &.unread {
    background: $grey5;
  }
}
