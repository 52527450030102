// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.item {
  height: 35px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.highlighted {
  background: $cyan;
  color: $white;
}
