// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.tooltipText {
  text-transform: uppercase;
}

.appNameWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  padding-bottom: 30px !important;
}

.appNameInput {
  display: flex;
  padding-top: 10px;

  & input {
    width: 25vw !important;
  }

  & div:last-of-type p {
    border: 0;
    line-height: 20px;
  }
}

.actionBtns {
  display: flex;
  margin-right: 10px;
  height: 50px;
}

.saveBtn,
.cancelEditBtn,
.triggerEditBtn {
  align-self: center;
  cursor: pointer;
}

.saveBtn {
  height: 30px;
  margin-left: 20px;
}

.cancelEditBtn {
  align-self: center;
  height: 25px;
  width: 25px;
  margin: 0;
  padding: 0;
  margin-left: 15px;
}

.cancelEditIcon {
  color: darken($orange, 5%);

  &:hover {
    color: lighten($orange, 5%);
  }
}

.triggerEditBtn {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  color: $grey65;

  &:hover {
    color: $grey85;
  }
}
