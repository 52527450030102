// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "shared";

.uploaderProgressContainer {
  @extend %uploaderContainer;
  padding: 16px 15px 23px;
  position: relative;
}

.uploaderProgressBar {
  margin-bottom: 5px;
  height: 10px;
}

.progressHeader {
  margin-bottom: 7px;
}

.progressCancelButton {
  position: absolute;
  top: -21px;
  right: -13px;
}

.progressCancelIcon {
  display: inline;
  font-size: initial;
  cursor: pointer;
  color: $slate;

  &:hover {
    color: $orange;
  }
}

.bold {
  font-weight: bold;
}
