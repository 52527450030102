// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$viewer-link-background: #e3e3e3;

.viewer {
  height: 400px;
  overflow-y: scroll;
  border: 1px solid $grey10;
  margin: 0 0 15px;
}

.buttonWrapper {
  text-align: center;
}

.button {
  margin: 0 auto;
}
