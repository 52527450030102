// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.contentBox {
  padding-bottom: 20px;
  border-bottom: 1px solid $grey10;

  p {
    margin-top: 20px;
  }
  em {
    font-style: italic;
  }
}

.informationHeader {
  margin-bottom: 25px;
  line-height: 120%;
}

.additionalInformation {
  padding: 30px;
  box-sizing: border-box;
  background-color: $cream;
  color: $grey60;
  line-height: 120%;
}
