// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.image404Wrapper {
  margin-bottom: 10px;
}

.image404 {
  display: block;
  margin: 0 auto;
}

.header {
  margin-bottom: 28px;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: $grey60;
}

.text {
  margin-bottom: 13px;
  font-size: 15px;
  font-style: italic;
  text-align: center;
}
