// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "components/form/elements/formElements";

.wrapped,
.simple {
  .resolutionWrapper {
    @extend %element;
    display: flex;

    &.active {
      border-color: $cyan;
      background-color: $white
    }

    &.disabled,
    &.disabled .resolutionInput {
      background-color: $grey25;
      cursor: default;
      color: $grey50;
    }

    .resolutionInput,
    .resolutionInput:active,
    .resolutionInput:hover {
      border-width: 0 0 1px 0;
      border-color: $element-border-color;
      padding: 0;
      flex: 0 1;
      font: 400 18px "Montserrat", sans-serif;
      text-align: center;
      background: transparent;
      outline: none;
      width: 60px;
      margin: 0 5px -1px;
    }
  }
}

.wrapped {
    @extend %elementWrapper;
}
