// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.searchableList {
  background-color: $white;
  margin: 8px 0;
}

.searchList {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px;
  width: 100%;
}

.searchBar {
  position: relative;
  margin: 0;
  border-color: $orange;

  &::after {
    font-family: "Grabson", sans-serif;
    content: "\e021";
    position: absolute;
    left: 20px;
    top: 5px;
    font-size: 26px;
    font-weight: 100;
    color: $grey55;
  }

  input[type="text"] {
    @extend %element;

    border: 0;
    border-bottom: 1px solid $grey10;
    padding-left: 60px;
    font-size: 15px;
    margin: 0;

    [class*="big"] &,
    [class*="huge"] & {
      position: relative;
      height: 45px;
      padding: 0 10px 0 50px;
      border: 1px solid $element-border-color;
      font-size: 18px;
      outline: none;
      font-weight: 100;
      background: $grey5;

      &::placeholder {
        color: $grey30;
      }

      &:focus {
        color: $baseTextColor;
        background: $white;
        border-color: $cyan;
      }
    }
  }
}

.listFooter {
  bottom: 0;
  height: 15px;
  font-size: 14px;
  font-style: italic;
  padding: 5px;
  color: $grey35;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    // sass-lint:disable-line no-universal-selectors
    padding-right: 10px;
  }
}

.listNoItemsMessage {
  min-height: 45px;
  border-bottom: 1px solid $grey10;
  text-align: center;
  background: $grey5;
  color: $grey40;
  font-style: italic;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.listNoItemsMessageText {
  flex-grow: 1;
}

.error {
  color: $orange;
}
