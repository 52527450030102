// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  background: $blue;
  padding: 50px;
  color: $white;
  text-align: center;
  position: relative;
}

.backgroundImg {
  display: block;
  position: absolute;
  top: 0;
  right: 40px;
  opacity: 0.05;
}

.logo {
  margin: 10px;
}

.name {
  font-size: 32px;
  font-weight: 100;
  margin: 10px 0;
}

.id {
  font-weight: 100;
  margin: 10px 0;
}

.description {
  @extend %text;
  font-size: 14px;
  font-weight: 100;
  margin: 20px 40px;
  color: $grey10;
}

.line {
  height: 1px;
  background: lighten($blue, 20%);
  margin: 30px 0 20px;
}

.email {
  margin: 10px 0;
  color: $cyan;
}

.phone {
  margin: 10px 0;
  color: $grey10;
}
