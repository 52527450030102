// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.label {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.name {
  flex-grow: 1;
}
