// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  width: 10px;
  height: 10px;
  margin: 6px;
  border-radius: 5px;
  background-color: $grey20;
}

.active {
  background-color: $cyan;
}
