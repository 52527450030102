// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.sectionDevices {
  margin-left: 225px;
}

.noDevicesRow {
  color: $slate;
}

.headerName {
  width: 25%;
}

.headerRegions {
  width: 70%;
}

.headerMenu {
  width: 15%;
}

.modelName {
  color: $blue;
}

.modelRegions {
  color: $blue;
}

.menu {
  float: right;
}
