// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: fixed !important; // sass-lint:disable-line no-important
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); // sass-lint:disable-line no-color-literals
  z-index: $zIndexLayer5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 40px;
}

.disableScroll {
  overflow: hidden;
}

%window {
  background-color: $white;
  box-shadow: 1px 1px 5px 0 $grey50;
  padding: 40px;
  animation-name: scale;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  overflow: auto;
  box-sizing: border-box;
  max-height: 100%;
}

.small {
 @extend %window;
 width: 450px;
}

.medium {
  @extend %window;
  width: 650px;
}

.big {
  @extend %window;
  width: 850px;
}

.huge {
  @extend %window;
  width: 1000px;
}

@keyframes scale {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.buttonClose {
  float: right;
  margin-top: -20px;
  margin-right: -20px;
  color: $slate;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s;
  border: none;
  outline: none;
  background-color: transparent;

  &:hover {
    color: $orange;
    transform: scale(1.2);
  }
}

.breadcrumbs {
  margin: -15px 0 10px;
  font-size: 12px;
}

.header {
  line-height: 1 !important;
  margin-bottom: 30px;
}

.content {
  @extend %text;
}
