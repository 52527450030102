// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$DateColumnWidth: 150px;

.DateColumn {
  width: $DateColumnWidth;
  min-width: $DateColumnWidth;
}

.MessageRead {
  background-color: $grey5;
}
