// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

::selection {
  background: $grey25;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $grey20;
  margin: 20px 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/*
 * Set the default text color cause black (#000) almost never occurs in nature
 */

html,
input,
select,
textarea {
  color: $baseTextColor;
}


html {
  font-size: 1em;
  line-height: 1.4;
  outline: 0 solid transparent;
}

body {
  min-width: 450px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.wrapper {
  min-height: 100vh;
  background-color: $grey10;
  position: relative;
}

.backgroundImg {
  position: absolute;
  top: 0;
  right: 40px;
  opacity: 0.05;
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.pageCollapsed {
  margin-left: 64px;
  width: calc(100% - 64px);
  position: relative;
}

.pageExpanded {
  margin-left: 200px;
  width: calc(100% - 200px);
  position: relative;
}

.pageContent {
  padding: 0 40px 0 0;
}

.topMenu {
  margin: 0 0 0 40px;
  text-align: right;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer {
  text-align: right;
  border-color: $slate;
  border-top: 2px solid;
  margin: 20px 40px 0 0;
  padding: 10px 0 20px;
}
