// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "../elements/formElements";

.wrapped {
  @extend %elementWrapper;
}

.buttons {
  margin: 10px 0;
  height: 30px;
  overflow: hidden;
  clear: both;

  .uploadButton {
    float: left;
  }
}

.themeSwitch {
    float: right;
  transform: translateY(12px);
  display: flex;
  align-items: center;
}

.modeName {
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 500;
  }

.modeName::after {
  content: ":";
  }

.switchButton {
  margin-top: 2px;
  margin-left: 5px;
}

.editor {
  padding-top: 0;
  border: 2px solid $grey20;
}
