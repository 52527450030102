// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "shared";

.modalContentContainer {
  display: flex;
  margin-bottom: 20px;
}

.buttonsWrapper {
  margin: 30px 0 0;
}

.appsListContainer {
  width: $appsListWidth;
  min-width: $appsListWidth;
  flex: 0;
  margin-right: 50px;
}

.plansListContainer {
  flex: 1;
}

.errorMessage {
  margin: 0;
}
