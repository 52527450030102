// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"),
    url("./fonts/Montserrat/montserrat-v11-latin-300.eot"),
    url("./fonts/Montserrat/montserrat-v11-latin-300.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Montserrat/montserrat-v11-latin-300.woff2") format("woff2"),
    url("./fonts/Montserrat/montserrat-v11-latin-300.woff") format("woff"),
    url("./fonts/Montserrat/montserrat-v11-latin-300.ttf") format("truetype"),
    url("./fonts/Montserrat/montserrat-v11-latin-300.svg#Montserrat")
      format("svg");
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"),
    url("./fonts/Montserrat/montserrat-v11-latin-regular.eot"),
    url("./fonts/Montserrat/montserrat-v11-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Montserrat/montserrat-v11-latin-regular.woff2") format("woff2"),
    url("./fonts/Montserrat/montserrat-v11-latin-regular.woff") format("woff"),
    url("./fonts/Montserrat/montserrat-v11-latin-regular.ttf") format("truetype"),
    url("./fonts/Montserrat/montserrat-v11-latin-regular.svg#Montserrat")
      format("svg");
}

/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"),
    url("./fonts/Montserrat/montserrat-v11-latin-500.eot"),
    url("./fonts/Montserrat/montserrat-v11-latin-500.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Montserrat/montserrat-v11-latin-500.woff2") format("woff2"),
    url("./fonts/Montserrat/montserrat-v11-latin-500.woff") format("woff"),
    url("./fonts/Montserrat/montserrat-v11-latin-500.ttf") format("truetype"),
    url("./fonts/Montserrat/montserrat-v11-latin-500.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"),
    url("./fonts/Montserrat/montserrat-v11-latin-600.eot"),
    url("./fonts/Montserrat/montserrat-v11-latin-600.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Montserrat/montserrat-v11-latin-600.woff2") format("woff2"),
    url("./fonts/Montserrat/montserrat-v11-latin-600.woff") format("woff"),
    url("./fonts/Montserrat/montserrat-v11-latin-600.ttf") format("truetype"),
    url("./fonts/Montserrat/montserrat-v11-latin-600.svg#Montserrat")
      format("svg");
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"),
    url("./fonts/Montserrat/montserrat-v11-latin-700.eot"),
    url("./fonts/Montserrat/montserrat-v11-latin-700.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Montserrat/montserrat-v11-latin-700.woff2") format("woff2"),
    url("./fonts/Montserrat/montserrat-v11-latin-700.woff") format("woff"),
    url("./fonts/Montserrat/montserrat-v11-latin-700.ttf") format("truetype"),
    url("./fonts/Montserrat/montserrat-v11-latin-700.svg#Montserrat")
      format("svg");
}

@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Code Pro"),
    url("./fonts/SourceCodePro/SourceCodePro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Code Pro"),
    url("./fonts/SourceCodePro/SourceCodePro-Bold.ttf") format("truetype");
}
