// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zIndexLayer2;
  height: 100%;
  width: 100%;
  background-color: $white;
  opacity: 0.9;
}
