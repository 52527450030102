// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.TableRow {
  border-bottom-style: none;
  padding-top: 2px;
}

.TableRowCollapsed {
  cursor: pointer;
}

.AuthorAvatar {
  margin-right: 10px;
  margin-left: 10px;
}

.AuthorName {
  position: relative;
  top: -5px;
  color: $blue;
}

.Type {
  color: $slate;
  font-size: 12px;
}
