// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.default {
  display: flex;
  flex-direction: column;
}

.horizontal {
  display: flex;
  align-items: baseline;
}

.valueText {
  margin: 10px 0;
}

.error {
  margin: 8px 0;
}

.border {
  border-color: $grey10;
}
