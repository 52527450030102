// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$progressBarHeight: 10px;

.progressbar {
  width: 100%;
  height: $progressBarHeight;
  background-color: $grey15;
  position: relative;
}

%progress {
  height: 100%;
}

.progressError {
  @extend %progress;

  background-color: $orange;
}

.progress {
  @extend %progress;

  background-color: $darkblue;
}

.percentageText {
  position: absolute;
  bottom: $progressBarHeight;
  right: 0;
  font-size: 12px;
  color: $slate;
}
