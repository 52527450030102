// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  height: 45px;
  border-bottom: 1px solid $grey10;
  background: $white;
}

.highlight {
  &:hover {
    background: $grey5 !important; // sass-lint:disable-line no-important
    transition: background $highlightDuration ease-out;
  }
}
