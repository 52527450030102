// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.customFieldRadio {
  padding: 0;
  align-items: start;

  input[type="radio"] {
    margin-top: 25px;
  }
}
