// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.snapPreview {
  margin: 0;
  padding-left: 20px;
  width: 50%;
  float: right;
  box-sizing: border-box;

  .textWrapper {
    margin: 25px 0 0;
    border-bottom: 1px solid $grey10;
    p {
      color: $baseTextColor;
      line-height: 140%;
      margin: 25px 0;
    }

    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $blue;
    }

    .warning {
      margin: 25px 0;
    }

    i::before {
      color: $grey20;
    }
  }

  .buttonWrapper {
    margin: 25px 0;
  }
}
