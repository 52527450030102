@import "components/form/elements/formElements";

$padding: 10px;

.wrapped {
  @extend %elementWrapper;
}

.row {
  display: flex;
}

.selectWrapper {
  flex-shrink: 0;
  margin-left: 10px;
  position: relative;
  width: 160px;

  &::after {
    color: $slate;
    content: "\e001";
    font-family: "grabson-micro", sans-serif;
    font-size: 14px;
    line-height: 42px;
    pointer-events: none;
    position: absolute;
    right: $padding;
    top: $padding;
  }
}

.selectWrapper:first-of-type {
  margin-left: 0;
}

.selectWrapper select {
  @extend %element;

  appearance: none;
  border-radius: 0;
  cursor: pointer;
  padding: $padding;
  color: $grey20;

  &:-moz-focusring {
    // sass-lint:disable-line no-vendor-prefixes
    color: transparent;
    text-shadow: 0 0 0 $black;
  }
}

.selectWrapper select:disabled {
  @extend %disabled;
}
