// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  border-bottom: 1px solid;
  border-color: $grey10;
  height: 50px;
  display: flex;
  align-items: center;
}

.disabled {
  background: $grey5;
}
