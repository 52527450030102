// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: absolute;
  border: solid 3px;
  box-sizing: border-box;
  font-size: 14px;
  overflow: hidden;
}

.label {
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $white;
  font-weight: bold;
}

.overflow {
  border-left: none;
}

.scheduled {
  background-color: $sunshine;
  border-color: darken($sunshine, 20%);

  .label {
    color: $white;
  }

  &:hover {
    border-width: 4px;
  }
}

.ongoing {
  background-color: $olive;
  border-color: darken($olive, 20%);

  .label {
    color: $white;
  }

  &:hover {
    border-width: 4px;
  }
}

.finished {
  background-color: $grey20;
  border-color: $grey35;

  .label {
    color: $grey35;
  }

  &:hover {
    border-width: 4px;
  }
}

.disabled {
  background-color: $orange;
  border-color: darken($orange, 20%);

  .label {
    color: $white;
  }

  &:hover {
    border-width: 4px;
  }
}
