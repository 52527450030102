// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.container {
  padding-left: 11px;
}

.item {
  font-size: 14px;
  font-weight: 500;
}
