// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.languageSelector {
  @extend %elementWrapper;

  .buttonWrapper {
    margin: 0;
    flex: none;
    width: 150px;

    .defaultButton {
      width: 100%;
      margin-top: 36px;
    }
  }
}
