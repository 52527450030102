// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  width: 460px;
}

.row {
  border-bottom: 1px solid;
  border-color: $grey10;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.checkbox {
  padding-left: 10px;
}

.expandContainer {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.arrowIcon {
  color: $grey20;
  padding-left: 5px;
  padding-right: 10px;
}
