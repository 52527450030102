// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  padding: 5px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.dateInput {
  p {
    padding: 10px;
    width: 250px;
    color: $blue;
    margin-top: 5px;
    font-size: 22px;
    background-color: $grey5;
    user-select: none;
    outline: none;
    box-sizing: border-box;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid $cyan;
    }
  }
}

.pagination {
  width: 200px;

  button {
    cursor: pointer;
    background: none;
    border: 0;
    user-select: none;
    outline: none;
    color: $blue;
    font-size: 14px;

    &:hover {
      color: $orange;
    }
  }

  .today {
    color: $cyan;
    font-weight: bold;
    border: solid 1px $grey10;
    border-radius: 5px;
    padding: 5px;
  }
}

.scale {
  button {
    background: none;
    border: 0;
    user-select: none;
    outline: none;
    padding-left: 5px;
    text-transform: uppercase;
    color: $blue;
    border-right: solid 2px lighten($blue, 20%);
    font-size: 14px;
    cursor: pointer;

    &:last-child {
      border-right: none;
      padding-right: 0;
    }

    &:hover {
      color: $orange;
    }
  }

  .active {
    color: $orange;
    font-weight: bold;
  }
}
