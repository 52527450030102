// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.titleText {
  font-weight: 500;
}

.linkToApp {
  display: block;
}

.descriptionText {
  @extend %text;
}

.linkToApp,
.ownerWebsite,
.supportEmail {
  @extend %text;
  word-break: break-all;
}

.inlineList li {
  display: inline;
}
.inlineList li:not(:last-of-type)::after {
  content: ", ";
}
