// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.checkAllButton {
  padding-left: 5px;

  .checkAllButtonCheckbox {
    margin-right: 6px;
  }
}
