// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "subTab";

.wrapperWithName {
  display: grid;
  grid-template-columns: 75px 25px auto 25px;
  grid-template-rows: 35px;
  border-bottom: 2px solid $grey10;

  .tabs {
    grid-column-start: 3;
    grid-column-end: 4;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: hidden;
    position: relative;
    margin-bottom: -2px;
  }

  .arrowLeft {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .arrowRight {
    grid-column-start: 4;
    grid-column-end: 5;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: 35px;
  border-bottom: 2px solid $grey10;

  .tabs {
    grid-column-start: 2;
    grid-column-end: 3;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: hidden;
    position: relative;
    margin-bottom: -2px;
  }

  .arrowLeft {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .arrowRight {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

.name {
  @extend %container;
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: center;
  color: $black;
  cursor: default;
}
