// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "src/components/elements/SearchableList/SearchableListPanel.scss";

$devices-list-height: 255px;

.checkAllButton {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  height: $devices-list-height;
  max-height: $devices-list-height;
  width: 100%;
}

.listHeader {
  @extend .container;
  padding-left: 10px;

  .checkAllOption {
    margin: 4px 0 0 4px;
  }

  .columnHeaders {
    display: flex;
    flex-grow: 1;
    margin: 0 16px 0 5px;

    * {
      min-width: 250px;
      font-size: 12px;
      color: $slate;
      text-transform: uppercase;
    }

    :last-child {
      min-width: initial;
      flex-grow: 1;
    }
  }
}
