// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "./Version";

.premiumPill {
  margin-left: 10px;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 10px;
  text-transform: uppercase;
  background-color: $green;
  color: $white;
}
