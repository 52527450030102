// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.subSection {
  padding: 10px 0;
}

.header {
  margin: 0 0 10px;
}

.children {
  border-bottom: 1px solid $grey10;
  padding: 0 0 20px;
}

.tooltip {
  color: $blue;

  &:hover {
    color: lighten($blue, 10%);
  }
}
