// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.dragHeader {
  width: 50px;
}

.titleHeader {
  width: 200px;
}

.contentHeader {
  width: 200px;
}

.descriptionHeader {
  min-width: 400px;
}

.menuHeader {
  width: 70px;
}

th {
  box-sizing: border-box;
}

.table {
  margin: 0;
}

.asterisks {
  color: $orange;
}

.sectionHeader {
  padding-top: 20px;
}
