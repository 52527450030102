// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.3;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  outline: none;

  .children {
    transition: transform .1s;
  }

  &:active {
    .children {
      transform: scale(0.95);
    }
  }

  &:disabled,
  &.disabled {
    cursor: default;
    opacity: 0.5;

    .children {
      transform: none;
    }
  }
}

%wizardButton {
  @extend %button;
  border-radius: 0;
  font-weight: 700;
}

%menuButton {
  @extend %button;
  color: $slate;
  background: none;

  &:hover {
    background-color: $grey10;
  }
}

//button sizes
.large {
  padding: 22px 40px;
  font-size: 14px;
}

.default {
  padding: 7px 20px;
  font-size: 12px;
}

.small {
  padding: 3px 10px;
  font-size: 10px;
}

// button types
.red {
  @extend %button;

  color: $white;
  background-color: $orange;

  &:not(:disabled):not(.disabled):hover {
    background-color: lighten($orange, 3%);
  }
}

.green {
  @extend %button;

  color: $white;
  background-color: $green;

  &:not(:disabled):not(.disabled):hover {
    background-color: lighten($green, 3%);
  }
}

.normal {
  @extend %button;

  color: $blue;
  background-color: $grey10;

  &:not(:disabled):not(.disabled):hover {
    background-color: lighten($grey10, 3%);
  }
}

.navy {
  @extend %button;

  color: $white;
  border-color: $blue;
  background-color: $blue;
}

.plain {
  @extend %button;

  color: $blue;
  background-color: transparent;
}

.wizardNormal {
  @extend %wizardButton;

  background-color: $cyan;
  color: $white;

  &:disabled {
    background-color: $grey10;
    color: $grey25;
    opacity: 1;
  }

  &:not(:disabled):not(.disabled):hover {
    background-color: lighten($cyan, 3%);
  }
}

.wizardSubmit {
  @extend %wizardButton;

  background-color: $olive;
  color: $white;

  &:not(:disabled):not(.disabled):hover {
    background-color: lighten($olive, 3%);
  }
}

.menuButton {
  @extend %menuButton;
  padding: 2px 7px;
}

.menuButtonSmall {
  @extend %menuButton;
  padding: 1px 4px;
}

// needed
.disabled {} // sass-lint:disable-line no-empty-rulesets

.loaderWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}
