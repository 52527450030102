// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.uploaderWrapper {
  @extend %elementWrapper;

  padding-bottom: 0;
  display: flex;
}

.uploader {
  flex: 1;
  align-self: flex-end;
}

.imageWrapper {
  margin: 0 0 15px 20px;
  background: $grey10;
  height: 100px;
  width: 100px;

  img {
    width: 100%;
    height: 100%;
  }
}

.landscape {
  width: 177px;
}
