// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.welcome {
    background: $white;
    padding: 40px;
    border-bottom: 2px solid $grey15;
    display: flex;
    flex-direction: row;
    margin: 0 0 30px 0;
    align-items: center;
    h1,
    p {
        padding-left: 20px;
        font-size: 18px;
    }
    h1 {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 30px;
        color: $grey50;
    }
    .dashboardAvatar {
        border: 4px solid $grey15;
    }
}
.header {
    margin-bottom: 32px;
}
.widgets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
        width: 47%;
    }
}
.welcomeMessage {
  @extend %text;
}
