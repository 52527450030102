// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.appIdColumn {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 58%;
}

.button {
  line-height: 1.5;
}

.actionMenu {
  width: 40px;
}
