// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.auto,
.fixed {
  width: 100%;
  border: none;
  margin-top: 10px;
}
.fixed {
  table-layout: fixed;
}
