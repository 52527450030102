// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  color: $blue;
  font-size: 13px;
  margin-left: 20px;
  padding: 5px;
}

.account {
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}

