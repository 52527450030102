// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.column {
  min-width: 250px;
  align-self: center;
  color: $grey80;

  &:last-of-type {
    min-width: initial;
    flex-grow: 1;
  }
}

.row {
  display: flex;
  margin-left: 10px;
}

.label {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
}

.targetName {
  flex-grow: 1;
  display: flex;
}
