// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$checkerboard-gradient: linear-gradient(
  45deg,
  $grey5 25%,
  transparent 25%,
  transparent 75%,
  $grey5 75%
);

.assetIcon {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 150px;
  align-items: stretch;
}

.assetIconArea {
  // pseudo-transparent background
  background-image: $checkerboard-gradient, $checkerboard-gradient;
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
  height: 110px;
  width: 150px;
}

.assetIconTitle {
  font-size: 12px;
  padding-bottom: 6px;
}

.uploader {
  label {
    padding-left: 32px;
    padding-right: 32px;
  }
}
