// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.logo {
  height: 284px;
  margin-bottom: 60px;
}

.text {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: $grey50;
  margin-top: 15px;

  &::after {
    content: "...";
    position: absolute;
  }
}
