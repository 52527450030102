// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%tab {
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;
  padding: 0 0 15px;
  margin-right: 30px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  color: $blue;

  i {
    padding-right: 5px;
  }
}

.tab {
  @extend %tab;
}

.active {
  @extend %tab;

  color: $orange;
  border-bottom: 5px solid;
  padding-bottom: 10px;
  border-color: $orange;
}

.default {
  $color: $blue;
}

.error {
  color: $orange;
}

.success {
  color: $olive;
}
