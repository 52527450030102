// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.row {
  font-weight: bold;
}

.columnName {
  color: $blue;
  cursor: pointer;

  &:hover {
    color: lighten($blue, 10%);
  }
}

.statusCountColumn {
  margin: 0;
  text-align: center;
}

.expandedIcon {
  color: $cyan;
}

.onHoldCountColumn {
  text-align: center;
}
