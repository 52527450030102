// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.spinner {
  $spinner-anim-duration: 1800ms;

  display: inline-block;
  position: relative;

  > div {
    border-color: $grey35;
    border: solid 0.25em;
    border-radius: 50%;
    will-change: transform;
    animation: spinner-ripple $spinner-anim-duration cubic-bezier(0, 0.2, 0.8, 1) infinite;
    opacity: 1;
    position: absolute;
    top: -0.25em;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > div:nth-child(2) {
    animation-delay: (-2 * $spinner-anim-duration) / 3;
  }

  > div:nth-child(3) {
    animation-delay: (-$spinner-anim-duration) / 3;
  }
}

@keyframes spinner-ripple {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.small {
  width: 15px;
  height: 15px;
}

.medium {
  width: 30px;
  height: 30px;
}

.large {
  width: 45px;
  height: 45px;
}
