// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$borderWidth: 1px;
$marginBetween: 14px;

.default,
.light {
  vertical-align: middle;
  white-space: nowrap;
  display: inline;

  > span,
  > a {
    padding: 0 12px;
    border: $borderWidth solid $grey20;
    border-radius: 15px;
    line-height: 25px;
    color: $grey40;
    display: inline-block;
    position: relative;
  }

  > a {
    text-decoration: none;

    &:hover {
      color: lighten($grey40, 10%);
    }
  }

  > span + span,
  > a + a {
    margin-left: $marginBetween;

    &::before {
      content: "+";
      width: $marginBetween;
      text-align: center;
      color: $grey40;
      position: absolute;
      left: -($marginBetween + $borderWidth);
    }
  }

  .active {
    border-color: $green;
    color: $green;
  }

  a.active {
    &:hover {
      color: lighten($green, 10%);
    }
  }

  .created {
    border-color: $cyan;
    color: $cyan;
  }

  a.created {
    &:hover {
      color: lighten($cyan, 10%);
    }
  }
}

.light {
  > span + span,
  > a + a {
    &::before {
      left: -$marginBetween;
    }
  }

  .active,
  .created {
    border: none;
    padding: 0;
  }
}
