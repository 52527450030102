// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.Markdown {
  @extend %text;

  h1 {
    font-size: 1.3em;
    display: block;
    font-weight: bold;
    margin: 0.3em 0;
  }

  h2 {
    margin: 0.3em 0;
    font-size: 1.1em;
    display: block;
    font-weight: bold;
  }

  ul {
    display: block;
    list-style-type: circle;
    padding-left: 2em;
  }

  ol {
    display: block;
    list-style-type: decimal;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: $blue;
  }

  code {
    background-color: rgba($black, 0.05);
    font-family: "Source Code Pro", monospace;
    border-radius: 3px;
    color: $black;
    margin: 0 0.1em;
  }

  pre code {
    margin: 1em 0;
    display: block;
    padding: 1em;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  p {
    margin-bottom: 10px;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid $grey30;
    padding-left: 10px;
    color: $grey60;
  }
}
