// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.selectedModels {
  border-bottom: 1px solid $grey10;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
