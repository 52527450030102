// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$highlight-bg: rgb(0, 100, 0);
$highlight-bg-selected: rgb(0, 0, 255);

.pdf :global {
  .textLayer {
    bottom: 0;
    left: 0;
    line-height: 1;
    opacity: 0.2;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  .textLayer > span {
    color: transparent;
    cursor: text;
    position: absolute;
    transform-origin: 0% 0%;
    white-space: pre;
  }

  .textLayer .highlight {
    background-color: $orange;
    border-radius: 4px;
    margin: -1px;
    padding: 1px;
  }

  .textLayer .highlight.begin {
    border-radius: 4px 0 0 4px;
  }

  .textLayer .highlight.end {
    border-radius: 0 4px 4px 0;
  }

  .textLayer .highlight.middle {
    border-radius: 0;
  }

  .textLayer .highlight.selected {
    background-color: $highlight-bg;
  }

  .textLayer ::selection { background: $highlight-bg-selected; }

  .textLayer .endOfContent {
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    user-select: none;
    z-index: $zIndexLayerMinus1;
  }

  .textLayer .endOfContent.active {
    top: 0;
  }
}
