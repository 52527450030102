// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.header {
  margin-bottom: 10px;
  font-size: 12px;
  color: $slate;
  text-transform: uppercase;
}

.asset {
  font-weight: bold;
}

.searchableList {
  height: 300px;
  max-height: 420px;
  overflow-y: auto;
}

.listPanel {
  width: 460px;
}

.listItemRow {
  border-bottom: 1px solid;
  border-color: $grey10;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.listItemSubRow {
  border-bottom: 1px solid;
  border-color: $grey10;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.listItemSubSubRow {
  border-bottom: 1px solid;
  border-color: $grey10;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.container {
  width: 460px;
  border-bottom: 1px solid;
  border-color: $grey10;
  height: 50px;
  display: flex;
  align-items: center;
}

.expandContainer {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.arrowIcon {
  color: $grey20;
  padding-right: 10px;
}

.checkbox {
  padding-left: 5px;
  padding-right: 5px;
}

.errorMessage {
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
  color: $orange;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
