// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

$zIndexLayerMinus1: -1;
$zIndexLayer1: 10;
$zIndexLayer2: 100;
$zIndexLayer3: 200;
$zIndexLayer4: 300;
$zIndexLayer5: 999;
