// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.button {
  margin-top: 20px;
}

.avatarColumn {
  flex: 0;
  margin-top: 20px;
}

.removeColumn {
  width: 35px;
  color: $slate;
  cursor: pointer;
}
