// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.wrapper {
  margin: 10px 0;

  & > * { // sass-lint:disable-line no-universal-selectors
    margin-right: 5px;
  }
}

.alignLeft {
  text-align: left;
}

.alignModal,
.alignRight {
  text-align: right;

  & > *:last-child { // sass-lint:disable-line no-universal-selectors
    margin-right: 0;
  }
}

.alignCenter {
  text-align: center;
}

.alignModal {
  margin: 30px 0 0;
}
