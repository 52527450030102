// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.SearchDropdown {
  position: absolute;
  width: 100%;
  z-index: $zIndexLayer1;
  box-sizing: border-box;
  background: $grey5;
}

.SearchDropdownWithOptions {
  padding: 27px 0;
  overflow-y: scroll;
  max-height: 50vh;
}

.Section {
  list-style-type: none;
  margin-bottom: 12px;
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Header {
  margin-left: 30px;
  color: $grey60;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid;
  border-color: $grey15;
  padding-bottom: 3px;
  margin-bottom: 2px;
}

.Option {
  cursor: pointer;
}
