// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

$blue: #376179;
$darkblue: #141227;
$orange: #ec5a4f;
$red: #b90924;
$sunshine: #ffb819;
$cyan: #00adee;
$cream: #fff1d2;
$olive: #babd56;
$slate: #8e9a9a;
$white: #fff;
$green: #2fa356;

$grey2: #fafafa;
$grey5: #f2f2f2;
$grey10: #e5e5e5;
$grey15: #d9d9d9;
$grey20: #ccc;
$grey25: #bfbfbf;
$grey30: #b2b2b2;
$grey35: #a6a6a6;
$grey40: #999;
$grey45: #8c8c8c;
$grey50: #808080;
$grey55: #737373;
$grey60: #666;
$grey65: #595959;
$grey70: #4d4d4d;
$grey75: #404040;
$grey80: #333;
$grey85: #262626;
$grey90: #1a1a1a;
$grey95: #0d0d0d;
$black: #232323;

$baseTextColor: $grey90;
