// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.PostTo {
  font-size: 14px;
}

.Dropdown {
  margin-left: 0.5em;
}
