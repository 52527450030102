// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "table";

.container {
  border: none;

  td {
    background-color: $grey10;
  }
}
.sticky {
  td {
    position: sticky;
    top: $thHeight;
  }
}
