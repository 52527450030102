// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.addButtonContainer {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
