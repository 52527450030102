// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$labelInputSpacing: 10px;

.container {
  border-bottom: 1px solid $grey10;
  padding: 8px 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.containerRtl {
  justify-content: flex-end;

  .input {
    margin: 0 0 0 $labelInputSpacing;
  }
}

.containerLtr .input {
  margin: 0 $labelInputSpacing 0 0;
}

.label {
  font-weight: bold;
}
