// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.columnBasicInfoLeft {
  flex: auto;
  width: 85%;

  > div {
    width: 100%;
    max-width: none;
  }
}

.columnBasicInfoRight {
  flex: auto;
  width: 150px;
}

.button {
  margin-top: 20px;
}

.error {
  margin: 0;
}

.titleWrapper {
  flex-grow: 1;
}

.idWrapper {
  width: 300px;
}
