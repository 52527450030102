// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.counter {
  flex-shrink: 0;
}

.results {
  font-size: 0.9em;
  padding: 19px 0;
  display: inline-flex;
}

.total {
  font-weight: 500;
}
