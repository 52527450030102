// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  margin: 10px;
  width: max-content;
  color: $baseTextColor;
}

.input {
  padding: 8px 12px;
  border: 1px solid $grey10;
  border-radius: 7px;
  text-align: center;
  font-size: 1em;
  max-width: 30px;
  outline: none;

  &:active,
  &:focus {
    border-color: $cyan;
  }
}
