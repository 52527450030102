// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.countryList {
  @extend %text;
  columns: 3 10px;
  width: 100%;

  .item {
    font-size: 14px;
    padding: 5px 0;
    display: inline-block;
    width: 100%;
  }
}
