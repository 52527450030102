// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$InfoMessageMarginBottom: 20px;

.table {
  table-layout: fixed;

  th:first-child {
    width: 25px;
  }
}

.infoMessage {
  margin-bottom: $InfoMessageMarginBottom;
}

.revenueWarning {
  margin: 32px 0 $InfoMessageMarginBottom;
}

.statusColumn {
  text-align: center;
}
