// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.name {
  font-size: 12px;
  font-weight: 500;
}

.columnName {
  color: $blue;
  cursor: pointer;
  padding-left: 12px;

  &:hover {
    color: lighten($blue, 10%);
  }
}

.statusCountColumn {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.onHoldCountColumn {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.expandedIcon {
  color: $cyan;
}
