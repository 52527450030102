// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "components/typography/Link/link";

.textSection {
  line-height: 1.5;

  strong {
    font-weight: 600;
  }

  p {
    margin-bottom: 20px;
  }

  a {
    @include createLinkColors($blue);
  }
}

.manifestSection {
  overflow: hidden;
  position: relative;
  transition: max-height 1s;
  max-height: 1500px;

  .toggleButton {
    padding: 9px 10px;
    vertical-align: top;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    background: linear-gradient(0, $white, transparent);
    position: absolute;
    top: 1500px;
    transition: top 1s;
  }

  &.hidden {
    max-height: 175px;
  }

  &.hidden::after {
    top: 125px;
  }
}

.summaryTable {
  border-top: 1px solid $grey10;

  .nameRow {
    width: calc(max(250px, 20vw));

    &::after {
      content: ":";
      float: right;
      margin-top: -1em;
    }
  }
}

.imagePreview {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
