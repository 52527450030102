// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.header {
  margin-bottom: 10px;
  font-size: 12px;
  color: $slate;
  text-transform: uppercase;
}
