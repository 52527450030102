// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.devicePairing {
  p {
    @extend %text;
    margin: 0.5em 0;
  }

  strong {
    font-weight: bold;
  }

  .midHeader {
    font-weight: bold;
    margin-top: 2em;
  }
}
