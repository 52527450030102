// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$countries-list-height: 250px;

.tabsContainer {
  margin-top: 20px;
}

.searchableList {
  max-height: $countries-list-height;
  height: $countries-list-height;
}

.checkAllButton {
  padding-right: 19px;
}
