// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.table,
.editTable {
  margin-bottom: 30px;
  color: $grey60;

  .row {
    border-left: transparent solid 4px;
  }

  .rowSuccess {
    border-left: $green solid 4px;
  }

  .rowError {
    border-left: $orange solid 4px;
  }

  .rowLive {
    background-color: lighten($grey5, 3%);
  }

  .rowEmpty {
    border-bottom: 1px solid $grey10;
    vertical-align: middle;
    text-align: center;
    height: 45px;
    color: $grey40;
  }

  .rowDescription {
    color: $grey50;
    height: 25px;

    td {
      vertical-align: top;
      font-size: 12px;
    }
  }

  .noBorder {
    border-bottom: 0;
  }

  .extendedIconColumn {
    width: 24px;
  }

  .optionsColumn {
    width: 40px;
  }

  .iconColumn,
  .orderColumn {
    width: 44px;
  }

  .typeColumn {
    width: 110px;
  }

  .titleColumn {
    width: 99px;
  }

  .liveTitleColumn {
    width: 30%;
  }

  .contentColumn {
    width: 70%;
  }

  .thumbnail {
    position: relative;
    > div {
      position: absolute;
      top: 10px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
  }
}

%overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.33;
}

.editTable {
  tr[draggable="true"] {
    cursor: pointer;
  }

  .titleColumn {
    width: 35%;

    > div {
      @extend %overflow;
    }
  }

  td.titleColumn div {
    font-size: 16px;
    font-weight: 500;
  }

  .contentColumn {
    width: 65%;

    > div {
      @extend %overflow;
    }
  }

  .acceptable {
    cursor: grabbing;
    background: $green;
    transition: background-color 0.4ms ease-in;

    &:nth-child(odd) {
      cursor: grabbing;
      background: $green;
      transition: background-color 0.4ms ease-in;
    }

    & [class*="Column"] {
      transition: color 0.5ms ease-in;
      color: $white;
    }
  }
  .dragging {
    cursor: grabbing;
    background: $grey65;
    transition: background-color 0.4ms ease-in;

    &:nth-child(odd) {
      cursor: grabbing;
      background: $grey65;
      transition: background-color 0.4ms ease-in;
    }

    & [class*="Column"] {
      transition: color 0.5ms ease-in;
      color: $white;
    }
  }
  .clickable {
    &:hover {
      cursor: pointer;
    }
  }
}

.checkboxAsOption {
  div {
    margin: 0;
  }
  input {
    display: none !important; // sass-lint:disable-line no-important
  }
}
