// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.logo {
  width: 36px;
  height: 36px;
}

.logoColumn {
  width: 50px;
}

.expandColumn {
  width: 25px;
  cursor: pointer;
}

.roleRow {
  height: 35px;
  background-color: $grey5;
}

.removeIcon {
  color: $orange;
  cursor: pointer;
}

.deleteColumn {
  width: 70px;
  text-align: right;
}

.addOrganizationButton {
  margin-top: 20px;
}
