// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.statusContainer {
  font-weight: 500;
  font-size: 14px;
  color: $black;
}

.list {
  max-height: 40vh;
}
