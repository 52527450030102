// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.breadcrumbs {
  margin: 0 0 0 40px;
  color: $blue;
  font-size: 12px;

  a {
    font-size: 12px;
    color: $blue;
    text-decoration: none;
    display: inline-block;
  }
}

.text {
  vertical-align: middle;
}

.active {
  a {
    color: $slate;
  }
  span {
    color: $blue;
  }
}

.inactive {
  a {
    color: $blue;
  }
  span {
    color: $slate;
  }
}

.icon {
  margin: 0 3px;
}
