// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.button {
  color: $slate;
  background: none;
}

.icon {
  margin-right: 5px;
  display: inline;
}

.tooltipHeader {
  font-weight: bold;
  display: block;
}

.tooltipParagraph {
  padding-top: 5px;
}
