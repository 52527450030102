// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.row {
  background-color: inherit;
}

.dragging {
  display: table;
  width: unset;
  background-color: $grey10;
}

.dragColumn {
  width: 50px;

  .dragIcon {
    color: $grey45;
  }
}

.nameColumn {
  font-size: 14px;
  font-weight: 600;
  width: 300px;
}

.includeAppsColumn {
  min-width: 200px;
  color: $blue;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuColumn {
  width: 70px;
  text-align: right;
}

.moveIcon {
  color: $grey45;
  cursor: pointer;
}

.arrows {
  width: 70px;
  text-align: right;
}
