// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%info {
  @extend %text;
  padding: 20px 35px;
  margin: 0 0 40px;
  text-align: center;
  border-style: solid;
  border-width: 4px;
  font-size: 14px;
}

.error {
  @extend %info;
  border-color: $orange;
}

.warning {
  @extend %info;
  border-color: $sunshine;
}

.normal {
  @extend %info;
  border-color: $green;
}
