// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  max-width: 350px;
}

.checkboxes {
  margin-top: 20px;
}

.checkbox {
  font-weight: bold !important; // sass-lint:disable-line no-important
  color: $black !important; // sass-lint:disable-line no-important
}
