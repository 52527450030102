// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

input[type="search"] {
  -webkit-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
}

/* Should work for Chrome, Edge & Safari */
input[type="search"]::-webkit-search-cancel-button {  // sass-lint:disable-line no-vendor-prefixes
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
}
