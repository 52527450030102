// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

/**
SIZES
 */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.5;
}
.h1 { font-size: 48px; }
.h2 { font-size: 36px; }
.h3 { font-size: 24px; }
.h4 { font-size: 18px; }
.h5 { font-size: 14px; }
.h6 { font-size: 12px; }

/**
STYLES
 */
.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

/**
COLORS
 */
.primary {
  color: $baseTextColor;
}

.secondary {
  color: $grey60;
}

.tertiary {
  color: $slate;
}

.tooltipIcon {
  font-weight: 100;
  margin-left: 5px;
  font-size: 12px;
  color: $grey20;
  vertical-align: top;
}
