// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.certification {
  @extend %text;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: $grey10;
  padding: 15px 0;

  p {
    padding: 5px 0;
  }

  b {
    font-weight: 700;
  }
}
