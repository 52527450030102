// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: relative;
  padding: 40px;
  background-color: $white;
  min-height: 70vh;
  min-width: 700px;
}

.containerWithBanner {
  position: relative;
  padding: 40px;
  padding-top: 80px;
  background-color: $white;
  min-height: 70vh;
  min-width: 700px;
}