// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.error,
.info,
.success {
  margin: 40px 0;
  padding: 25px;
  display: block;
  text-align: center;
  border: 4px solid $orange;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.info {
  border-color: $olive;
}
.success {
  border-color: $green;
}
