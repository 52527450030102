// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%container {
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  border-radius: 1px;
  font-weight: 700;
  font-size: 11px;
  color: $slate;
  border: 2px solid transparent;

  i {
    font-size: 11px;
    padding-right: 5px;
  }
}

.container {
  @extend %container;
}

.active {
  @extend %container;

  color: $black;
  border-color: $grey10;
  border-bottom-color: $white;

  &.gray2 {
    background: $grey2;
    border-bottom-color: $grey2;
  }
}
