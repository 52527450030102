// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "components/form/elements/formElements";

.colorWrapper {
  display: block !important;
}

.colorTrigger {
  position: relative;

  input[type="text"] {
    padding-left: 52px !important;
    padding-right: 38px !important;
  }

  .colorPreview {
    border-right: 2px solid $element-border-color;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 2px;
    left: 2px;
    cursor: pointer;
  }

  .colorIcon {
    position: absolute;
    top: 9px;
    right: 7px;
    color: $grey45;
    cursor: pointer;
  }
}

.colorPopupWrapper {
  margin: 0 10px;
}

.colorPopup {
  padding: 3px 20px 10px;

  .colorPicker {
    height: 300px !important;

    [class="react-colorful__saturation"],
    [class="react-colorful__hue"],
    [class="react-colorful__alpha react-colorful__last-control"] {
      margin-top: 39px;
      border-radius: 5px;
    }

    [class="react-colorful__saturation"] {
      margin-top: 32px;
    }

    [class="react-colorful__saturation"]:before,
    [class="react-colorful__hue"]:before,
    [class="react-colorful__alpha react-colorful__last-control"]:before {
      position: relative;
      top: -28px;
      color: $slate;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
    }

    [class="react-colorful__saturation"]:before {
      content: "Saturation / Lightness"
    }

    [class="react-colorful__hue"]:before {
      content: "Color (Hue)"
    }

    [class="react-colorful__alpha react-colorful__last-control"]:before {
      content: "Opacity (Aplha)"
    }
  }

  .colorPresetHeader {
    margin-top: 11px;
  }

  .colorPresetList {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0;
    }
  }

  .colorPresetButton {
    margin: 10px 10px 0 0;
    padding: 0;

    .colorPresetIcon {
      margin: 0;
      border: 1px solid $element-border-color;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      display: block;
    }

    .colorPresetTooltip {
      padding: 5px 10px;
      min-width: auto;
      p {
        font-weight: 600;
        font-style: normal;
      }
    }
  }
}
