// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.label {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: stretch;
  width: 100%;

  &.disabled {
    background: $grey5;
    color: $grey40;
  }

  .countryAvatar {
    margin: 0 10px 5px 20px;
  }

  .countryInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .name {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  .countryCheckbox {
    margin-right: 18px;
  }
}

