// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

@mixin selectedTabIndicator () {
  content: " ";
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
  display: block;
  position: absolute;
  right: -12px;
  z-index: $zIndexLayer1;
  background-color: $white;
  margin-top: -4px;
}

@mixin leftIndicator () {
  content: "\25CF";
  margin: 0 7px 0 11px;
  width: 10px;
}

.verticalTabs {
  display: flex;
  flex-direction: row;
  background-color: $grey5;
}

.verticalTabsMenu {
  flex: 0 0 200px;
}

.tab {
  display: flex;
  color: $baseTextColor;
  padding: 10px 20px 10px 0;
  margin: 0;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $grey10;
    color: $baseTextColor;

    .remove {
      visibility: visible;
    }
  }

  &:focus {
    color: $baseTextColor;
  }

  &.default {
    font-weight: bold;
    &::before {
      @include leftIndicator;
      color: transparent;
    }
  }

  &.default.invalid:hover {
    &::before {
      @include leftIndicator;
      color: $orange;
      display: inline;
    }
  }

  &.noRemove {
    &:hover {
      .remove {
        visibility: hidden;
      }
    }
  }

  &.invalid {
    &::before {
      @include leftIndicator;
      color: $orange;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .remove {
      display: none;
    }

    &:hover {
      .remove {
        display: inline;
      }

      &::before {
        display: none;
      }
    }
  }

  &.selected {
    background-color: $grey10;
    color: $grey60;

    &::after {
      @include selectedTabIndicator;
    }
  }
}

.remove {
  border: none;
  background: none;
  visibility: hidden;
  margin: -2px 6px 0 8px;
  padding: 0;
  cursor: pointer;

  &:active,
  &:focus {
    color: $baseTextColor;
  }
}

.verticalTabsContent {
  padding-left: 20px;
  flex-grow: 1;
  background: $white;

  > div {
    margin-top: -20px;
  }
}

.addButton {
  width: 100%;
  color: $blue;
  font-size: 16px;
  text-align: left;
  background: transparent;
  padding: 15px 14px;
  font-weight: 700;
  border: 0;
  cursor: pointer;
}
