// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.welcome {
  p {
    @extend %text;
  }
}

.sectionHeader {
  transition: color .5s;

  &.disabled {
    color: $grey15;
  }
}

.checkboxInput {
  margin: 20px 0;

  input {
    position: absolute;
  }
}

.checkboxTitle {
  padding: 3px 28px;
  font-weight: bold;
  transition: color .3s;
}

.checkboxDescription {
  @extend %text;
  padding: 10px 28px;
  font-weight: normal;
  transition: color .3s;

  strong {
    font-weight: bold;
  }
}
