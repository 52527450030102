// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.helpBoxTextWrapper {
  display: block;

  p {
    margin-bottom: 15px;
  }

  a {
    color: $blue;

    &:visited {
      color: $blue;
    }
  }

  &::after {
    content: "";
    clear: both;
    display: table;
  }
}
.logoWrapper {
  display: block;
  float: right;
}
