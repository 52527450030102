// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "GeoMap";

$list-height: 250px;

.list {
  height: $list-height;
  max-height: $list-height;
}

.listRow {
  max-width: $list-column-width;
  flex-flow: wrap;
}

.countryLabel {
  height: 100%;
}

.checkAllButton {
  padding-right: 35px !important; // sass-lint:disable-line no-important
}
