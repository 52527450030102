// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
