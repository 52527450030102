// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.label {
    display: flex;
    align-items: center;
    height: 1.4rem;
}

.tooltip {
    font-size: .9rem;
    margin-left: .5rem;
    font-weight: 100;
}