// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 71.5%;
  float: left;
}

.background {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("./prevBack.svg");
  background-size: contain;
  background-repeat: no-repeat;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("./prevFront.png");
    background-size: contain;
  }
}

.smallIcon {
  position: absolute;
  left: 12%;
  top: 40%;
  width: 7%;
}

.icon {
  position: absolute;
  left: 9.9%;
  top: 59%;
  width: 11.3%;
}

.thumbnail {
  top: 13.4%;
  position: absolute;
  left: 6.9%;
  width: 17.6%;
}

.billboard {
  top: 29%;
  position: absolute;
  left: 29.8%;
  width: 68.1%;
}

.screenshot {
  display: block;
  position: absolute;
  width: 25%;
  top: 57.2%;
  left: 68%;
}

.screenshotImg {
  width: 100%;
  height: 100%;
}
