// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.statusContainer {
  border-bottom: 2px solid;
  border-color: $grey10;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  > * {
    font-weight: 600;
  }
}

.draftColumn {
  display: flex;
  justify-content: flex-start;
}

.pillActive,
.pillNotActive {
  margin: 10px;
  font-size: 12px;
  padding: 2px 6px 2px;
  background-color: $grey5;
  border-radius: 10px;
  border: 1px solid $grey30;
  color: $black;
  display: flex;
  align-items: center;
  i {
    color: $green;
  }
}

.pillNotActive {
  background-color: $white;
  color: $grey25;
  i {
    color: $grey20;
  }
}

.discardButton {
  padding: 0;
  i {
    font-size: 20px;
  }
}

.discardIcon {
  color: $orange;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
}

.statusNotActiveIcon {
  color: $green;
}

.arrow {
  margin: 0;
  > img {
    width: 40px;
    padding-top: 10px;
  }
}
