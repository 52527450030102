// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.pending {
  color: $sunshine;
}

.rejected {
  color: $orange;
}

.approved {
  color: $green;
}

.statusIcon {
  padding: 10px 5px;
  display: inline-block;
  color: $sunshine;
}

.planStatusCell {
  text-transform: capitalize;
}

.warningsColumnHeader {
  text-align: center;
}

.nameColumn {
  font-weight: bold;
}

.checkAll {
  display: inline-block;
  margin-left: 5px;
}

.selectedItemsText {
  display: inline-block;
  margin-right: 18px;
}

.selectedItemsCount {
  width: 35px;
  text-align: right;
  margin-right: 5px;
  display: inline-block;
}

.bulkActionSelect {
  padding: 0;
  width: 300px;
}

.bulkActionSelectDisabled select {
  color: $grey30;
}
