// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.Avatar {
  display: inline-block;
  overflow: hidden;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
  background: $white;
}
