// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "../Version";

.commentHeader {
  margin: 20px 0px 10px;
}

.commentText {
  @extend %text;
  font-size: 13px;
}

.list {
  @extend %text;
}
