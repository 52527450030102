// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px -45px;
  z-index: $zIndexLayer4;
  position: relative;
  & > div:first-of-type {
    flex-basis: 15%;
  }
  ul {
    flex: auto;
  }
  .btnPrevious {
    background-color: #f2f2f1;
    color: $blue;
    &:not(:disabled):not(.disabled):hover {
      background-color: lighten($grey15, 3%);
    }
  }
}

.draftButtonsContainer {
  flex-basis: 30%;
  display: flex;
  justify-content: space-evenly;
}

.tabName {
  text-transform: uppercase;
  color: $slate;
  font-size: 24px;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 200px;
  padding-right: 20px;
}
