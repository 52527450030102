// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "shared";

@mixin nonClickableOverlay {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin columnHeader {
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 11px;
}

.headers {
  border-bottom: 1px solid $grey10;
  margin-top: 14px;
  display: flex;
}

.checkAll {
  display: inline-block;
  margin: 0 6px 0 24px;
}

.statusHeader {
  @include columnHeader;
  cursor: pointer;
}

.statusHeaderDisabled {
  color: $grey15;
}

.includedAppHeader {
  @include columnHeader;
  flex: 1;
  display: inline-block;
  padding-right: 22px;
  text-align: right;
}

.list {
  margin: 0px;
  min-height: $planListContainerHeight;
}

.listContent {
  height: $planListHeight;
  max-height: $planListHeight;
}

.selectedCount {
  padding: 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: $grey70;
}

.statusTooltip {
  text-transform: initial;
  font-weight: normal;
  cursor: auto;
}

.tooltipHeader {
  margin-bottom: 5px;
}

.tooltipRow {
  display: flex;
}

.tooltipCheckbox {
  @include nonClickableOverlay;
  flex: 0;
}

.tooltipCheckboxDescription {
  padding-top: 6px;
  flex: 1;
}
