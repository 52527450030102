// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.validationBadge {
  p {
    color: $orange;
    font-weight: 400;
    font-size: 16px;
    border-left: 3px solid $orange;
    padding: 5px 10px;
    margin: 10px 0 0;

    a {
      color: $blue;
    }
  }
}
