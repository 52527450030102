// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.row {
  height: 90px;

  td {
    box-sizing: border-box;
  }
}

.error {
  border: 2px solid $orange;
}

.dragging {
  display: flex;
  align-items: center;
  background-color: $grey10;
}

.dragColumn {
  min-width: 50px;

  .dragIcon {
    color: $grey45;
  }
}

.titleColumn {
  min-width: 200px;
  width: 200px;
  color: $blue;
  font-style: italic;
  overflow-wrap: break-word;
}

.contentColumn {
  min-width: 200px;
  position: relative;
}

.descriptionColumn {
  padding: 10px 0;
  min-width: 400px;
  font-style: italic;
  text-align: center;
  overflow-wrap: break-word;
}

.noDataDescription {
  color: $grey45;
}

.menuColumn {
  min-width: 70px;
  text-align: right;
}

.imgPreview {
  max-height: 70px;
  object-fit: cover;
  border: 1px solid $grey10;
}

.playImage {
  position: absolute;
  top: 50%;
  left: 50px;
  width: 35px;
  transform: translate(0, -50%);
}
