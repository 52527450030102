// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.row {
  height: initial;
}

.label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.planName {
  flex-grow: 1;
  padding-left: 6px;
  width: 0px;
  margin-top: 16px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 18px;
}

.checkbox {
  margin-left: 24px;
  margin-right: 30px;
}

.includedApps {
  padding: 0 36px;
  color: $slate;
  font-size: 12px;
}
