// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.previewImg {
  height: 225px;
  width: 225px;
  border: 1px solid $grey5;

  // pseudo-transparent background
  background-image: linear-gradient(
      45deg,
      $grey5 25%,
      transparent 25%,
      transparent 75%,
      $grey5 75%
    ),
    linear-gradient(
      45deg,
      $grey5 25%,
      transparent 25%,
      transparent 75%,
      $grey5 75%
    );
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}

.button {
  margin-top: 20px;
  display: inline-block;
}

.info {
  text-transform: uppercase;
  color: $slate;
  font-size: 11px;
  margin-top: 12px;
}
