// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$descriptItemsSpacing: 10px;

.PostToOptions {
  flex: auto;
}

.PostToButton {
  flex: auto;
  text-align: right;

  & > div {
    margin-top: 0;
  }
}

.Dropdown {
  margin-left: 0.5em;
}
