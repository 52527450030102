// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.SearchBar {
  position: relative;
  display: block;
  width: 100%;

  &::after {
    position: absolute;
    color: $grey30;
    left: 10px;
    top: 10px;
    font-size: 1.7em;
    line-height: 1;
    background-color: transparent;
    font-family: "grabson", sans-serif;
    content: "\e021";
  }

  .SearchBarInput {
    height: 45px;
    border: 2px solid $element-border-color;
    font-size: 18px;
    padding: 0 10px 0 50px;
    outline: none;
    width: 100%;
    font-weight: 100;
    position: relative;
    box-sizing: border-box;
    background: $grey5;

    &::placeholder {
      color: $grey30;
    }

    &:focus {
      color: $baseTextColor;
      background: $white;
      border-color: $cyan;
    }
  }

  &.for-white-bg {
    &::after {
      color: $slate;
    }

    .SearchBarInput {
      &::placeholder {
        color: $slate;
      }
    }
  }
}

.SearchHint {
  margin-top: 4px;
  font-size: 12px;
  color: $grey30;
}
