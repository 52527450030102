// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

@mixin createLinkColors ($color, $activeColor: $orange) {
  color: $color;

  &:visited {
    color: $color;
  }

  &:hover {
    color: lighten($color, 10%);
  }

  &:active {
    color: $activeColor;
  }
}

.link {
  @include createLinkColors($blue);
  text-decoration: none;
  cursor: pointer;
}
