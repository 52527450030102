// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.worldmap {
  fill: none;
}

.mapSvg {
  margin: 0 auto;
}

.continent {
  transition: opacity ease 0.2s;
  opacity: 1;
  stroke: $white;
  stroke-width: 0;
}

.continent.focused {
  stroke-width: 0.5px;
}

.continent.unfocused {
  opacity: 0;
  pointer-events: none;
}

.country {
  fill: $grey20;
  cursor: pointer;
}

.countrySelected {
  fill: $cyan;
}

.countryFocused {
  opacity: 0.6;
  fill: $cyan;
}

.countryDisabled {
  opacity: 0.2;
  fill: $grey15;
  cursor: default;
}

.countrySelectedDisabled {
  opacity: 0.2;
  fill: $cyan;
  cursor: default;
}
