// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$popupShift: -5px;

.shifted {
  &.popupBottom {
    margin-top: $popupShift;
  }

  &.popupTop {
    margin-bottom: $popupShift;
  }

  &.popupLeft {
    margin-right: $popupShift;
  }

  &.popupRight {
    margin-left: $popupShift;
  }

}
