// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    .table {
      margin: 0;
    }
  }

  .status {
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
  }
}
