// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.headerIcon {
  margin-right: 10px;
}

.section {
  margin-bottom: 20px;
}

.label {
  color: $slate;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.fileFormatButton {
  display: inline-block;
  margin-right: 20px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
