// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.listWrapper {
  border: 2px solid $grey10;
  border-top: none;
  box-sizing: border-box;

  position: absolute;
  width: 100%;
  z-index: $zIndexLayer1;

  background: $white;
}

.list {
  max-height: 150px;
  overflow-y: auto;
}

.listFooter {
  height: 30px;
  font-size: 14px;
  font-style: italic;
  padding: 5px;
  color: $grey35;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $grey5;

  & > * { // sass-lint:disable-line no-universal-selectors
    padding-right: 10px;
  }
}

.error {
  color: $orange;
}
