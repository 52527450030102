// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "shared";

.container {
  display: flex;
  flex-direction: column;
}

.appsList {
  max-height: $appsListMaxHeight;
  overflow: auto;
  border-right: 1px solid $grey10;
  flex: 1;
}

.appIcon {
  margin: 0 15px 0 5px;
}

.appName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  max-width: $maxAppNameWidth;
  font-size: 12px;
}
