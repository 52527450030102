// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.actionSelect {
  margin-right: 10px;
}

.actionButton {
  margin-top: 2px;
}
