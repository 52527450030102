// Copyright © 2023 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.buttons {
  margin: 10px 0;
  height: 30px;
  overflow: hidden;
}

.themeSwitch {
  float: right;
  transform: translateY(12px);
  display: flex;
  align-items: center;
}

.modeName {
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 500;
}

.modeName::after {
  content: ":";
}

.switchButton {
  margin-top: 2px;
  margin-left: 5px;
}
