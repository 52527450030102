// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.bold {
  padding-left: 5px;
  font-weight: bold;
  color: $blue;
}

.assets {
  color: $blue;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  color: $blue;
}

.menu {
  float: right;
}
