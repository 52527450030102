// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/colors";

.wrapper {
  height: calc(100vh - 280px);
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 270px;
  max-height: 630px;

  .table {
    margin-top: 15px;
  }
}

.selectHeader {
  width: 45px;
}

.selectedStatus {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  bottom: -55px;
}

.nameColumn {
  font-size: 13px;
  font-weight: 600;
}

.includeAppsColumn {
  color: $blue;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
