// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.activePreview {
  border-right: 5px solid $grey10;
  padding-right: 5px;
}

.inactivePreview {
  border-right: 5px solid transparent;
  padding-right: 5px;
}

