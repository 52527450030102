// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.container {
  padding-bottom: 5px;
  text-transform: capitalize;
}

.numberAndStatusColumn {
  display: flex;
  align-items: center;
}

.dash {
  margin: 0 5px;
}
