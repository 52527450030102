// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// sass-lint:disable class-name-format, pseudo-element

@charset "UTF-8";

@font-face {
  font-family: "grabson";
  src: url("./grabson/fonts/grabson.eot"),
    url("./grabson/fonts/grabson.eot?#iefix") format("embedded-opentype"),
    url("./grabson/fonts/grabson.woff") format("woff"),
    url("./grabson/fonts/grabson.ttf") format("truetype");
  //url("./fonts/grabson.svg#grabson") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "grabson-micro";
  src: url("./grabson-micro/fonts/grabson-micro.eot"),
    url("./grabson-micro/fonts/grabson-micro.eot?#iefix")
      format("embedded-opentype"),
    url("./grabson-micro/fonts/grabson-micro.woff") format("woff"),
    url("./grabson-micro/fonts/grabson-micro.ttf") format("truetype");
  //url("./fonts/grabson-micro.svg#grabson-micro") format("svg");
  font-weight: normal;
  font-style: normal;
}

.wrapper {
  display: inline-block;
}

.icon {
  vertical-align: middle;

  &::before {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
  }
}

.font-normal {
  font-family: "grabson", sans-serif;
}

.font-micro {
  font-family: "grabson-micro", sans-serif;
}

.icon-xs {
  font-size: 10px;
}

.icon-sm {
  font-size: 14px;
}

.icon-normal {
  font-size: 18px;
}

.icon-lg {
  font-size: 22px;
}

.icon-xl {
  font-size: 26px;
}

.icon-2xl {
  font-size: 32px;
}

.icon-3xl {
  font-size: 46px;
}

.icon-app::before {
  content: "\e000";
}

.icon-arrow-down::before {
  content: "\e001";
}

.icon-arrow-left::before {
  content: "\e002";
}

.icon-arrow-right::before {
  content: "\e003";
}

.icon-arrow-up::before {
  content: "\e004";
}

.icon-ce-html::before {
  content: "\e005";
}

.icon-close::before {
  content: "\e006";
}

.icon-details::before {
  content: "\e007";
}

.icon-device::before {
  content: "\e008";
}

.icon-download::before {
  content: "\e009";
}

.icon-drm::before {
  content: "\e00a";
}

.icon-edit::before {
  content: "\e00b";
}

.icon-grid::before {
  content: "\e00c";
}

.icon-guidelines::before {
  content: "\e00d";
}

.icon-help::before {
  content: "\e00e";
}

.icon-home::before {
  content: "\e00f";
}

.icon-html5::before {
  content: "\e010";
}

.icon-list::before {
  content: "\e011";
}

.icon-live::before {
  content: "\e012";
}

.icon-menu::before {
  content: "\e013";
}

.icon-message::before {
  content: "\e014";
}

.icon-minus::before {
  content: "\e015";
}

.icon-ok::before {
  content: "\e016";
}

.icon-organizations::before {
  content: "\e017";
}

.icon-payments::before {
  content: "\e018";
}

.icon-pending::before {
  content: "\e019";
}

.icon-play::before {
  content: "\e01a";
}

.icon-plus::before {
  content: "\e01b";
}

.icon-promotions::before {
  content: "\e01c";
}

.icon-radio::before {
  content: "\e01d";
}

.icon-regions::before {
  content: "\e01e";
}

.icon-rejected::before {
  content: "\e01f";
}

.icon-role-developer::before {
  content: "\e020";
}

.icon-search::before {
  content: "\e021";
}

.icon-settings::before {
  content: "\e022";
}

.icon-snap::before {
  content: "\e023";
}

.icon-stats::before {
  content: "\e024";
}

.icon-teams::before {
  content: "\e025";
}

.icon-testing::before {
  content: "\e026";
}

.icon-tv-platform::before {
  content: "\e027";
}

.icon-users::before {
  content: "\e028";
}

.icon-warning::before {
  content: "\e029";
}

.icon-image::before {
  content: "\e02a";
}

.icon-alert-circle::before {
  content: "\e02b";
}

.icon-export::before {
  content: "\e02c";
}

.icon-star::before {
  content: "\e02d";
}

.icon-structures::before {
  content: "\e02e";
}

.icon-listview::before {
  content: "\e02f";
}

.icon-gridview::before {
  content: "\e03a";
}

.icon-plus-circle::before {
  content: "\e03b";
}

.icon-cross-circle::before {
  content: "\e03c";
}

.icon-lock::before {
  content: "\e03d";
}

.icon-dragdrop::before {
  content: "\e03e";
}

.icon-dragdrop2::before {
  content: "\e041";
}

.icon-cloud::before {
  content: "\e03f";
}

.icon-close-filled::before {
  content: "\e040";
}

.icon-plus-filled::before {
  content: "\e042";
}

.icon-ok-filled::before {
  content: "\e043";
}

.icon-update::before {
  content: "\e044";
}

// don't remove
.icon-empty::before {
  content: "\e029";
  color: transparent;
}

.icon-device-inventory::before {
  content: "\e045";
}

.icon-help-filled::before {
  content: "\e046";
}

.icon-target_boarding:before {
  content: "\e048";
}

.icon-target_granular:before {
  content: "\e049";
}
