// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.wrapper {
  position: relative;
}

.img {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 34px;
  height: 34px;
}

.icon {
  position: absolute;
  top: 14px;
  right: 14px;
  color: $grey40;
}

.input {
  @extend %element;

  margin-bottom: 0;
  padding-right: 40px;
}

.normal {} // sass-lint:disable-line no-empty-rulesets

.selected {
  .icon {
    cursor: pointer;
  }
}

.withImg {
  .input {
    padding-left: 50px;
  }
}
