// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.tableActions {
  background-color: $white;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * { // sass-lint:disable-line no-universal-selectors
    vertical-align: middle;
    padding: 0;
  }
}
