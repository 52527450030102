// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%iconSize {
  width: 33px;
  height: 33px;
}

.actors {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 0;
}

.categories {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -63px;
}

.channels {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -126px;
}

.games {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -189px;
}

.genres {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -252px;
}

.home {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -315px;
}

.kids {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -378px;
}

.live {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -441px;
}

.movies {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -503px;
}

.music {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -566px;
}

.news {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -629px;
}

.series {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -691px;
}

.videos {
  @extend %iconSize;
  background-image: url("../../../../../images/snapIcons.png");
  background-position: 0 -754px;
}

.empty {
  @extend %iconSize;
  display: block;
  background-color: $grey10;
  border-radius: 50%;
}

.iconButton {
  cursor: pointer;
}

.pickerWrapper {
  position: relative;
  height: 77px;
  width: 50px;
  cursor: pointer;

  .iconPicker {
    margin: 14px 0;
  }

  .popUp {
    min-width: 160px;
  }
}


%iconWrap {
  width: 35px;
  height: 35px;
  display: inline-block;
  border: 2px solid transparent;
}

.iconWrapper {
  @extend %iconWrap;
}

.currentIcon {
  @extend %iconWrap;
  border: 2px solid $blue;
}

.disabledArrow {
  color: $grey30;
}
