// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  padding: 0 0 20px;
  border-bottom: 1px solid $grey10;
}

.checkbox {
  width: 70%;
  border-bottom: 1px solid $grey10;
  padding-bottom: 20px;
}
.sublabel {
  font-weight: 300;
  margin-top: 5px;
}

.radio {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
