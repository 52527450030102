// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

// This component requires some max-height to work correctly. Unfortunately,
// previous value of 1000px was not enough to display all countries
// in application details view.
$max-expanded-height: 3000px;

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    transition: color 0.2s;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.1em;
  }

  .sign {
    margin-left: 20px;
    font-size: 22px;
    color: $grey30;
    transition: color 0.2s;
  }
}

.childrenWrapper {
  overflow: hidden;
  max-height: 0;

  .children {
    margin-top: 10px;
  }
}

.Accordion {
  &.enabled .headerWrapper {
    cursor: pointer;
  }

  &.collapsed .childrenWrapper {
    transition: opacity 0.2s, max-height 0.5s cubic-bezier(0, 1, 0, 1);
    max-height: 0;
    opacity: 0;
  }

  &.expanded .childrenWrapper {
    transition: opacity 0.2s ease-in, max-height 0.5s ease-in;
    max-height: $max-expanded-height;
    opacity: 1;
  }

  &.default {
    &.enabled .headerWrapper {
      &:hover .header,
      &:hover .sign {
        color: $orange;
      }
    }
    &.expanded .sign::after {
      content: "-";
    }

    &.collapsed .sign::after {
      content: "+";
    }
  }
  &.compact {
    .sign {
      font-family: "grabson-micro";
      font-size: 12px;
    }

    &.expanded .sign::after {
      content: "\e004";
    }

    &.collapsed .sign::after {
      content: "\e001";
    }
  }
}
