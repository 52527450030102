// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.scoreInfo {
  margin-bottom: 20px;
  line-height: 1.33;
}
.scoreInfo strong {
  font-weight: bold;
}
