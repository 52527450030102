// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.menuSwitcher {
  padding: 10px 15px 15px 15px;
  text-align: right;

  &.collapsed {
    text-align: center;
  }
}

.icon {
  color: $slate;
  text-decoration: none;

  &:hover {
    color: $orange;
  }
}
