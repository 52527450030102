// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%sideMenu {
  background-color: $grey10;
  position: fixed;
  z-index: $zIndexLayer3;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.collapse {
  @extend %sideMenu;

  width: 64px;
}

.expand {
  @extend %sideMenu;

  width: 200px;
}

.menu {
  flex: 1;
  overflow-y: auto;
}

.logoImage {
  flex: 0;

  img {
    height: 140px;
  }
}

.menuSwitcher {
  flex: 0;
}
