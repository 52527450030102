// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.name {
  padding-left: 50px;
  color: $grey40;
}

.apps {
  color: $grey40;
}

.menuItem {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.menu {
  float: right;
}
