// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
.topPill {
  display: inline-block;
  line-height: 19px;
  margin-left: 10px;
  border-radius: 5px;
  padding: 1px 6px 0;
  font-size: 10px;
  text-transform: uppercase;
  background-color: $grey30;
  color: $white;
}

.icon {
  padding-left: 5px;
  line-height: 1;
}
