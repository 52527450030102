// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "formElements";

.wrapped,
.simple {
  textarea {
    @extend %element;

    resize: vertical;
    height: 160px;
  }

  textarea:read-only {
    @extend %readonly;
  }

  textarea:disabled {
    @extend %disabled;
  }
}

.wrapped {
  @extend %elementWrapper;
}
