// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  display: inline-block;
  background-color: $grey5;
  text-align: center;
  height: 100%;
  padding-top: 10px;
  color: $grey50;
  border-left: solid 1px $grey10;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 20px;

  &:nth-child(odd) {
    background-color: lighten($grey5, 3%);
  }
}

.active {
  background-color: $cyan !important; // sass-lint:disable-line no-important
  border-left: solid 3px $cyan;
  color: $white;
  font-weight: bold;

  .subLabel {
    color: $white;
  }
}

.disabled {
  background-color: $grey5;
}

.subLabel {
  font-size: 10px;
  color: $grey30;
}
