// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  position: relative;
}

.qualityRoundNumber {
  float: left;
  padding-right: 20px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
}

.loading {
  opacity: 0.5;
}

.p1 {
  color: $orange;
}

.p2 {
  color: $sunshine;
}

.p3 {
  color: $green;
}

.p4,
.p5 {
  color: $slate;
}
