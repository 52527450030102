// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$list-column-width: 250px;

.inlineTabs {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;

  li {
    cursor: pointer;
    display: inline-block;
    color: $slate;
    padding: 10px 20px;
    text-decoration: none;

    &:hover {
      color: $black;
    }

    &.active {
      color: $black;
      border-width: 2px 2px 0;
      border-style: solid;
      border-color: $grey5;
      border-radius: 5px 5px 0 0;
    }
  }
}

.geomapTopLevelContainer {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.mapContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listContainer {
  width: $list-column-width;
  min-width: $list-column-width;
}
