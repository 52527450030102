// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$itemHeight: 50px;
$displayedItems: 6.5;

.list {
  max-height: floor($itemHeight * $displayedItems);
  overflow: auto;
}

.listItem {
  background: $grey5;
  cursor: pointer;
  padding: 10px;
  margin: 2px 0;

  &:active,
  &:hover {
    background: $grey10;
    color: $blue;
  }
}

.activeOrganization {
  background: $slate;
  color: $white;

  &:active,
  &:hover {
    background: $slate;
    color: $white;
    cursor: default;
  }
}

.noOrganization {
  text-align: center;
}

.noOrganizationInfo {
  padding: 10px 0;
}

.createOrganization {
  margin: 0 0 10px;
}
