// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.blur {
  filter: blur(3px);
  user-select: none;
  pointer-events: none;
}
