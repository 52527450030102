// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.nameColumn {
  font-weight: bold;
}

//App statuses
.processing {
  color: $slate;
}

.published {
  color: $green;
}

.rejected {
  color: $sunshine;
}

.vewd_rejected {
  color: $orange;
}

.awaiting_test {
  color: $slate;
}

.testing {
  color: $sunshine;
}

.not_published {
  color: $slate;
}

.not_submitted {
  color: $slate;
}

//Certification statuses
.pending {
  color: $sunshine;
}

.done,
.accepted {
  color: $green;
}

//Moderation statuses
.handed_over_to_moderation {
  color: $slate;
}

.handed_over_to_premium_support {
  color: $slate;
}

.testing_standard {
  color: $sunshine;
}

.testing_all {
  color: $sunshine;
}

.testing_premium {
  color: $sunshine;
}

//Priorities
.p1 {
  color: $orange;
}

.p2 {
  color: $sunshine;
}

.p3 {
  color: $green;
}

.p4 {
  color: $slate;
}

.p5 {
  color: $slate;
}

//Application types
.snap_mrss {
  color: $blue;
}

.snap_brightcove {
  color: $blue;
}

.snap_oyala {
  color: $blue;
}

.snap_zype {
  color: $blue;
}

.snap_livestream {
  color: $blue;
}

.snap_jw_player {
  color: $blue;
}

.snap_kaltura {
  color: $blue;
}

.snap_daily_motion {
  color: $blue;
}

.html_5 {
  color: $orange;
}

.ce_html {
  color: $sunshine;
}

.native {
  color: $black;
}

.targetStatus {
  color: $green;
}

.active {
  color: $orange;
}

.disable {
  color: $slate;
}

.moderationStatusCell {
  text-transform: uppercase;
}

.ownerIsPremiumIcon {
  color: $sunshine;
  margin-left: 10px;
  line-height: 0;
  i {
    vertical-align: initial;
  }
}

.topPillTooltip {
  font-weight: normal;
}

.topPillPartialResult {
  background-color: $orange;
}