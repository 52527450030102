// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.SearchBarWithFilters {
  position: relative;
}

.suggestionsError {
  margin-top: 10px;
  margin-bottom: 0;
}

.availableFiltersError {
  margin-top: 10px;
  margin-bottom: 0;
}

.suggestionsLoader {
  padding: 10px;
}

.suggestionsExclude {
  color: $red;
  padding-left: 10px;
}