// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.icon {
  color: $slate;
}

.iconWarn {
  color: $red;
}

.button {
  cursor: pointer;
  font-weight: 500;
  color: $blue;
  margin-left: 5px;

  &:hover {
    color: lighten($blue, 10%);
  }
}

.disabled {
  cursor: default;
  color: initial;
}
