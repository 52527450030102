// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.moderationStatus {
  text-transform: capitalize;
  margin-bottom: 15px;
}

.link {
  color: $blue;
  font-weight: 500;
  padding-left: 5px;
  text-transform: none;
}

.moderationStatusButtons {
  margin-top: 0;
  margin-bottom: 15px;
}

.approveRejectButtons {
  margin-top: 0;
  margin-bottom: 20px;
}

.finalActionWarning {
  text-align: left;
  margin-bottom: 0;
}
