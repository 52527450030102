// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.header {
  margin-bottom: 10px;
  font-size: 12px;
  color: $slate;
  text-transform: uppercase;
}

.asset {
  font-weight: bold;
}

.tabsContainer {
  margin-top: 10px;
}

.searchableList {
  height: 200px;
}

.errorMessage {
  font-size: 14px;
  border: solid $orange 2px;
  margin: 15px 0;
  padding: 10px;

  b {
    font-weight: bold;
  }
}

.checkAllButton {
  padding-right: 20px;
}
