// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.publicationStateInfo {
  margin: 0;
  color: $grey45;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.link {
  color: $blue;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: lighten($blue, 10%);
  }
}

.name {
  font-weight: bold;
}
