// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

%subItem {
  color: $blue;
  transition: color 0.2s;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  display: block;
  line-height: 17px;
  position: static;
  padding-bottom: 3px;
}

.collapse {
  @extend %subItem;

  padding-right: 0;

  &:hover {
    color: $orange;
  }
}

.expand {
  @extend %subItem;

  padding-right: 53px;
  text-align: right;

  &:hover {
    color: $orange;
  }
}

.active {
  color: $orange;
}
