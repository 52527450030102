// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$enabledColor: $black;
$disabledColor: $grey20;

.arrow {
  width: 0;
  height: 0;
  border: 6px solid transparent;
}

%button {
  cursor: pointer;
  display: flex;
  align-items: center;

  &.disabled {
    cursor: auto;
  }
}

.left {
  @extend %button;
  justify-content: flex-start;

  .arrow {
    border-right-color: $enabledColor;
  }

  &.disabled {
    .arrow {
      border-right-color: $disabledColor;
    }
  }
}

.right {
  @extend %button;
  justify-content: flex-end;

  .arrow {
    border-left-color: $enabledColor;
  }

  &.disabled {
    .arrow {
      border-left-color: $disabledColor;
    }
  }
}
