// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "../elements/formElements";

.component {
  @extend %elementWrapper;
  border-color: $grey10;
  padding: 0;
}

.header {
  margin: 0;
}

.row {
  margin: 10px 0 15px;
}

.progress {
  display: flex;
  align-items: flex-end;
}

.left {
  flex-grow: 0;
  margin-right: 10px;
  padding: 0;
  color: $grey65;
}

.leftButton {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: $grey65;
}

.inactive {
  .left {
    color: $grey40;
  }
}

.right {
  padding-bottom: 5px;
  flex-grow: 1;
  position: relative;
}

.removeButton {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: $black;
  cursor: pointer;

  &:disabled {
    color: $grey35;
    cursor: default;
  }
}

.imgPreviewColumn {
  flex: 0 0;
}

.imgPreview {
  max-height: 70px;
  object-fit: cover;
  border: 1px solid $grey10;
}

.info {
  text-transform: uppercase;
  color: $slate;
  font-size: 11px;
  margin-top: 12px;
}

.hiddenInput {
  display: none;
}
