// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  border-bottom: 1px solid $grey10;
  vertical-align: middle;
  text-align: center;
  height: 45px;
  background: $grey5;
  color: $grey40;
  font-style: italic;
}

.container .errorInfo {
  margin-bottom: 0;
}
