// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.red {
  color: $orange;
}

.green {
  color: $green;
}

.yellow {
  color: $sunshine;
}

.grey {
  color: $slate;
}
