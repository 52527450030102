// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$arrowShift: 3px;
$popupShift: 8px;

.popup {
  padding: 15px;
  background: $white;
  text-align: left;
  outline: 1px solid $grey10;
}

.popupTop {
  margin-bottom: $popupShift;
}

.popupBottom {
  margin-top: $popupShift;
}

.popupLeft {
  margin-right: $popupShift;
}

.popupRight {
  margin-left: $popupShift;
}

.arrow {
  position: absolute;
  background: $white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.arrowTop {
  top: $arrowShift;
  box-shadow: -1px -1px 0 0 rgba($grey10, 1);
}

.arrowBottom {
  bottom: $arrowShift;
  box-shadow: 1px 1px 0 0 rgba($grey10, 1);
}

.arrowLeft {
  left: $arrowShift;
  box-shadow: -1px 1px 0 0 rgba($grey10, 1);
}

.arrowRight {
  right: $arrowShift;
  box-shadow: 1px -1px 0 0 rgba($grey10, 1);
}
