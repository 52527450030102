// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.dragHeader {
  width: 50px;
}

.nameHeader {
  width: 300px;
}

.includeAppsHeader {
  min-width: 200px;
}

.menuHeader {
  width: 70px;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
