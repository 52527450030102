// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";


.avatarColumn,
.optionsColumn {
  width: 40px;
}
.nameColumn {
  width: 33%;
}

.roleColumn {
  width: 66%;
}
.roleColumnWrapper {
  width: 100%;
}
.roleColumnContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}
.userName {
  font-weight: bold;
}
