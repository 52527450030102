// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

$activeColor: $orange;
$inactiveColor: $blue;
$iconColor: $slate;
$activeBackground: $white;
$borderColor: $grey10;
$itemHoverTransition: 0.6s;
$popupWidth: 165px;
// We have to overlay the popup on top of the scrollbar,
// as the :hover would break when moving mouse between
// .mainItem and popup
$sidebarCollapsedWidth: 64px;
$sidebarScrollWidth: 15px;
$popupLeft: $sidebarCollapsedWidth - $sidebarScrollWidth;

@mixin setMainItemColor($color) {
  .mainItemContent,
  .icon {
    color: $color;
  }
}

.navLink {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: $inactiveColor;
  white-space: nowrap;
  display: block;
}

.mainItem {
  &.mainItemActive,
  &:hover {
    @include setMainItemColor($activeColor);
  }
}

.mainItemContent {
  padding: 5px 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid $borderColor;
  border-right: none;
  border-left: none;
  transition: color $itemHoverTransition;
}

.icon {
  color: $iconColor;
  transition: color $itemHoverTransition;
  margin: 0 10px;
}

// Expanded:
.sideBarExpanded {
  &.mainItemActive {
    background-color: $activeBackground;
  }

  .mainItemContent {
    justify-content: flex-end;
  }
}

.subItemsExpanded {
  padding: 10px 0;
  font-size: 12px;
  text-transform: none;

  & > li:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

// Collapsed:
.sideBarCollapsed {
  .mainItemContent {
    justify-content: center;
  }

  &:hover {
    .mainItemContent {
      background-color: $activeBackground;
    }

    .collapsedPopup {
      display: block;
    }
  }
}

.subItemsCollapsed {
  padding: 10px 0 10px 20px;
  background-color: $activeBackground;
  border: 1px solid $borderColor;
  border-left-width: 0;
  border-top-width: 0;
  box-sizing: border-box;
}

.collapsedPopup {
  display: none;
  position: absolute;
  min-width: $popupWidth;
  z-index: $zIndexLayer1;
  font-size: 12px;
  // hack: we overlay the popup on top of the scrollbar,
  // but the left part of popup is transparent,
  // which makes the scrollbar to show-through
  left: $popupLeft;
  padding-left: $sidebarScrollWidth;
  background: transparent;

  .navLink {
    color: $activeColor;
    padding: 11px 0 13px 10px;
    display: block;
    background-color: $activeBackground;
    border: 1px solid $borderColor;
    border-left-width: 0;
  }
}
