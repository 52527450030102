// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "formElements";

.checkbox {
  margin: 5px 0;

  .label {
    cursor: pointer;
    user-select: none;
  }
}

.checkboxInput {
  @extend %element;

  width: auto;
  display: inline;
  margin: 2px 5px;
  cursor: pointer;
}

.disabled .label {
  color: $grey15;
}
