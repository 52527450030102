// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

@mixin textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  margin-top: -15px;
  margin-bottom: 20px;
  line-height: 19px;
  font-size: 12px;
  font-weight: 400;
  color: $grey50;
  text-transform: uppercase;
}

.row {
  margin-left: 20px;
}

.rowLabel {
  @include textOverflow;
  margin-left: 20px;
  max-width: 480px;
}

.tooltipWrapper {
  display: block;
}

.tooltipPopup {
  word-break: break-word;
  max-width: 480px;
}

.itemCount {
  display: block;
  margin-top: -30px;
  line-height: 21px;
  font-size: 10px;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
}
