// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "elements/formElements";

.formHeader {
  font-weight: bold;
  font-size: 24px;
  margin: 48px 0 26px;
  padding: 0;
  color: $grey60;
}

.errorLine {
  width: 50%;
  border: solid 2px;
  height: 48px;
  padding-left: 10px;
  margin-top: 0.3em;
  box-sizing: border-box;
  font-size: 1.2em;
  color: $orange;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  &:focus {
    background-color: $white;
    border-color: $cyan;
  }
}

.field {
  @extend %elementWrapper;
}

.videoType {
  font-weight: bolder;
}

.videoFormats {
  transition: opacity .5s;

  &.disabled {
    opacity: .5;
  }
}
