// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// sass-lint:disable no-vendor-prefixes

@import "styles/base";

.Tag {
  border-radius: 1px;
  background-color: $olive;
  color: $white;
  font-size: 11px;
  line-height: 13px;
  display: inline-block;
  padding: 6px 10px 5px;
  margin-right: 5px;
  margin-bottom: 5px;

  &:last-child {
    margin-right: 0;
  }

  .ValueContainer {
    display: inline-block;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }

  .Clickable {
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  .ClickableExclude {
    &:hover {
      color: $red;
    }
  }

  .Name,
  .Separator,
  .Value {
    display: inline-block;
    padding: 4px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 600px;
  }

  .Separator {
    padding: 0px 0.5em 4px 0px;
  }

  .Value {
    font-weight: bold;
  }

  .Delete {
    line-height: 8px;
    vertical-align: top;
  }

  .tooltip {
    word-break: break-word;
    max-width: 600px;
  }
}
