// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  padding: 0 40px;
}

.icon {
  color: $orange;
}

.header {
  text-transform: uppercase;
  padding: 15px 0 5px;
  color: $grey60;
}

.info {
  @extend %text;
  padding: 5px 0;
  color: $grey60;
  font-style: italic;
  margin-bottom: 50px;
}
