// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.notLoading {
  padding-top: -10px;
  height: 1px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.loading {
  padding-top: -10px;
  height: 1px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.loading::before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 50px;
  height: 1px;
  background-color: $blue;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 5%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}
