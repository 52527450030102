// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.item {
  & > * { // sass-lint:disable-line no-universal-selectors
    padding-left: 10px;
  }
}

.checkAllButton {
  padding-left: 15px;

  .checkAllButtonCheckbox {
    margin-right: 15px;
  }
}

.notAvailable {
  color: $grey20;

  .appAvatar img {
    opacity: 0.5;
  }
}

.notAvailableText {
  font-style: italic;
  margin-left: 5px;
  color: $grey40;
}

.notAvailableTextOnHold {
  color: $sunshine;
}
