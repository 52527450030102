// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.columnName {
  padding-left: 50px;
  color: $grey65;
  font-size: 12px;
}

.columnStatus {
  text-align: center;
}

.status {
  font-size: 12px;
  text-transform: lowercase;
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}

.yellow {
  color: $sunshine;
}

.green {
  color: $green;
}

.red {
  color: $orange;
}
