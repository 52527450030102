// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

.brightcove {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 0;
  width: 180px;
  height: 44px;
}

.comcast {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -74px;
  width: 178px;
  height: 37px;
}

.ivs {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -141px;
  width: 179px;
  height: 36px;
}

.jwplayer {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -207px;
  width: 158px;
  height: 57px;
}

.kaltura {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -294px;
  width: 180px;
  height: 42px;
}

.livestream {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -366px;
  width: 129px;
  height: 59px;
}

.mrss {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -455px;
  width: 158px;
  height: 30px;
}

.ooyala {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -515px;
  width: 180px;
  height: 29px;
}

.unreel {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -574px;
  width: 180px;
  height: 45px;
}

.zype {
  background-image: url("../../../../../images/snap-source-logo.png");
  background-position: 0 -649px;
  width: 157px;
  height: 43px;
}
