// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.section {
  margin-bottom: 45px;
}

.header {
  margin: 0 0 20px;
}
