// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";
@import "components/form/elements/formElements";

.memberList {
  @extend %elementWrapper;
}

.memberRow {
  border-bottom: 1px solid;
  border-color: $grey20;
  height: 65px;
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: stretch;
    width: 100%;

    .memberAvatar {
      margin: 0 10px 0 20px;
    }

    .memberInfo {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .username {
        font-weight: 400;
        margin-bottom: 5px;
        font-size: 18px;
        color: $baseTextColor;
      }

      .email {
        font-size: 12px;
      }
    }

    .memberCheckbox {
      margin-right: 18px;
    }
  }
}
