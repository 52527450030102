// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

@import "styles/base";

.container {
  margin-top: 0;
  display: block;
}

.tagsContainer {
  display: inline-flex;
  margin-top: 5px;
}

.filtersTagList {
  display: inline-block;
}

.filtersExcludeTagList {
  background-color: $orange !important; // sass-lint:disable-line no-important
}

.label {
  font-size: 11px;
  font-weight: bold;
  margin-right: 6px;
  line-height: 27px;
}

.filterHintContainer {
  margin: 5px 0;

  .positionRight {
    margin-left: auto;
  }
}

.filterHint {
  font-size: 12px;
  color: $grey30;

  .bold {
    font-weight: bold;
  }
}
